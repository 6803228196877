import React, { useContext, useState } from "react";
import ChallengePreview from "../components/dashboard/fitgets/challenge-preview/ChallengePreview";
import ChallengeProgress from "../components/dashboard/fitgets/challenge-progress/ChallengeProgress";
import LastSevenDays from "../components/dashboard/fitgets/last-seven-days/LastSevenDays";
import Today from "../components/dashboard/fitgets/today/Today";
import WeeklySteps from "../components/dashboard/fitgets/weekly-steps/WeeklySteps";
import UserContext from "../context/user.context";

const Dashboard = () => {
  const [shownChallenge, setShownChallenge] = useState();
  const { user } = useContext(UserContext);

  if (!user) {
    return <div>Loading...</div>;
  }

  return (
    <div
      className={`
      grid h-full w-full 
      grid-areas-dashboard md:grid-areas-dashboardBig 
      grid-cols-dashboard md:grid-cols-dashboardBig 
      grid-rows-dashboard md:grid-rows-dashboardBig 
      gap-2 md:gap-4 text-white max-w-7xl mx-auto pt-14 md:py-8`}
    >
      <ChallengePreview
        onChallengeChange={setShownChallenge}
        className="grid-in-challenge min-h-0 overflow-hidden"
      />
      <ChallengeProgress
        challenge={shownChallenge}
        className="grid-in-progress min-h-0"
      />
      <Today className="grid-in-today" />
      <WeeklySteps className="min-h-0 grid-in-week" />
      <LastSevenDays className="min-h-0 grid-in-sevendays" />
    </div>
  );
};

export default Dashboard;
