import moment from "moment";
import { useContext, useMemo } from "react";
import { RiHeartPulseFill } from "react-icons/ri";
import UserContext from "../../../../context/user.context";
import { useDailySteps } from "../../../../hooks/use-daily-steps.hook";
import DashboardTile from "../../dashboard-tile/DashboardTile";
import Bars from "../bars/Bars";

const DAYS = 7;

const LastSevenDays = ({ className }) => {
  const { dailySteps } = useDailySteps(DAYS);
  const { user } = useContext(UserContext);

  const barsForDailySteps = useMemo(() => {
    if (!dailySteps) {
      return new Array(DAYS).fill({ percentage: 0, label: "-" });
    }

    return dailySteps
      .sort((a, b) => (a.date > b.date ? 1 : -1))
      .map(({ date, steps }) => {
        return {
          percentage: Math.min(
            Math.round((steps / user.dailyStepGoal) * 100),
            100
          ),
          label: moment(date).format("ddd"),
        };
      });
  }, [dailySteps, user]);

  return (
    <DashboardTile title="Last seven days" className={`flex-[1] ${className}`}>
      {!user.provider && (
        <div className="flex flex-col items-center justify-center w-full h-full opacity-25">
          <RiHeartPulseFill className="w-16 h-16" />
          <div>Fitness data source missing.</div>
        </div>
      )}
      {user.provider && (
        <Bars className="mx-auto h-full" data={barsForDailySteps} />
      )}
    </DashboardTile>
  );
};

export default LastSevenDays;
