import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import { useNotificationContext } from "../../../context/notification.context";
import axiosInstance from "../../../util/Axios.util";
import { unknownError } from "../../../util/Notification.util";
import Logo from "../../shared/logo/Logo";

const PasswordResetForm = () => {
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const { notify } = useNotificationContext();
  const navigate = useNavigate();
  const { register, handleSubmit } = useForm({
    defaultValues: {
      password: null,
    },
  });

  const token = useMemo(() => searchParams.get("t"), [searchParams]);

  useEffect(() => {
    if (!token) {
      navigate("/auth");
    }
  }, [token, navigate]);

  const toLogin = useCallback(() => {
    navigate("/auth");
    searchParams.delete("t");
    setSearchParams(searchParams);
  }, [navigate, searchParams, setSearchParams]);

  const submit = useCallback(
    async ({ password }) => {
      try {
        setLoading(true);
        await axiosInstance.post("/auth/reset-password", { password, token });
        notify("success", {
          title: "Password changed successfully",
          description:
            "Your password has been changed! Feel free to sign in again.",
        });
        toLogin();
      } catch (err) {
        console.error(err);
        notify(...unknownError);
      } finally {
        setLoading(false);
      }
    },
    [toLogin, notify, token]
  );

  return (
    <form onSubmit={handleSubmit(submit)} className="flex flex-col gap-4">
      <Logo className="w-32 h-32 mx-auto" />
      <label>
        <div className="ml-2 text-sm">Choose a new password</div>
        <input
          className="oc-input"
          name="password"
          autoComplete="off"
          type="password"
          disabled={loading}
          {...register(`password`, { required: true })}
        />
      </label>
      <button
        disabled={loading}
        className="bg-lime-300 hover:bg-lime-400 text-black p-2 rounded-md font-bold mt-6"
      >
        Continue
      </button>
      <NavLink className="text-center mt-2" to="/">
        Go to Home
      </NavLink>
    </form>
  );
};

export default PasswordResetForm;
