import { RiHeartPulseFill } from "react-icons/ri";
import { NavLink } from "react-router-dom";

const FitnessDataMissing = ({ className }) => {
  return (
    <div
      className={`p-4 w-full h-full flex flex-col items-center justify-center ${className}`}
    >
      <h2 className="text-2xl font-bold mb-2">Connect fitness data source</h2>
      <div>
        To update your challenges you need a data source! Set one up now!
      </div>
      <NavLink to="/settings/data-source">
        <button className="rounded-full p-3 bg-purple-800 flex justify-center items-center gap-2 mt-6">
          <RiHeartPulseFill className="w-8 h-8 animate-heartbeat" />
          <div className="block font-bold">Pick data source</div>
        </button>
      </NavLink>
    </div>
  );
};

export default FitnessDataMissing;
