import { useMemo } from "react";
import { RiAlarmWarningFill } from "react-icons/ri";

const RouteFormError = ({ error }) => {
  const errorMessage = useMemo(() => {
    if (!error) {
      return null;
    }

    if (error.error.status === "NoRoute") {
      return "No route could be found for your waypoints. Try a different combination!";
    }

    if (error.error.status === "LowDistance") {
      return "You're route is too short. Try a different combination!";
    }

    return "An unknown error occurred. Please try again.";
  }, [error]);

  return (
    errorMessage && (
      <div className="flex gap-4 items-center p-4 border border-red-400 rounded-md">
        <RiAlarmWarningFill className="w-8 h-8 text-red-400" />
        <div className="flex-1 text-sm text-red-400">{errorMessage}</div>
      </div>
    )
  );
};

export default RouteFormError;
