const PageHeader = ({ children, to }) => {
  return (
    <div className="grid grid-cols-[max-content_1fr] items-center mb-4 px-2">
      {/*
      <NavLink
        className="p-4 block hover:bg-neutral-950 flex-initial rounded-md"
        to={to}
      >
        <button className="w-full flex items-center gap-2 text-sm">
          <RiArrowLeftCircleFill className="w-6 h-6" />
        </button>
      </NavLink>
      */}
      <h1 className="text-lg md:text-2xl text-center pr-12 font-bold text-lime-400">
        {children}
      </h1>
    </div>
  );
};

export default PageHeader;
