// Notifications.js
import React from "react";
import { useNotificationContext } from "../../context/notification.context";
import Notification from "./Notification";

const Notifications = () => {
  const { notifications } = useNotificationContext();

  return (
    <div className="fixed bottom-4 right-4 left-4 md:left-auto z-[1000000] text-right">
      {notifications.map((n) => (
        <Notification key={n.id} {...n} />
      ))}
    </div>
  );
};

export default Notifications;
