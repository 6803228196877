import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { RiArrowDropLeftLine, RiArrowDropRightLine } from "react-icons/ri";
import { NavLink } from "react-router-dom";
import UserContext from "../../../../context/user.context";
import { useCurrentChallenge } from "../../../../hooks/use-current-challenge.hook";
import ChallengeMap from "../../../challenge/challenge-map/ChallengeMap";
import ChallengeMissing from "../../../challenge/challenge-missing/ChallengeMissing";
import FitnessDataMissing from "../../../settings/fitness-data-missing/FitnessDataMissing";
import Loading from "../../../shared/loading/Loading";

const ChallengePreview = ({ onChallengeChange, className }) => {
  const { user } = useContext(UserContext);
  const { challenges, loading } = useCurrentChallenge();
  const [activeIndex, setActiveIndex] = useState(0);
  const challengeCount = useMemo(() => challenges?.length, [challenges]);
  const decreaseIndex = useCallback(
    () => setActiveIndex((activeIndex + challengeCount - 1) % challengeCount),
    [activeIndex, challengeCount]
  );
  const increaseIndex = useCallback(
    () => setActiveIndex((activeIndex + 1) % challengeCount),
    [activeIndex, challengeCount]
  );
  const shownChallenge = useMemo(() => {
    if (!challenges) {
      return null;
    }

    return challenges[activeIndex];
  }, [challenges, activeIndex]);

  useEffect(
    () => onChallengeChange && onChallengeChange(shownChallenge),
    [onChallengeChange, shownChallenge]
  );

  if (!loading && !user.provider) {
    return <FitnessDataMissing className={`bg-neutral-950 ${className}`} />;
  }

  if (!loading && challenges && !(challenges.length > 0)) {
    return <ChallengeMissing className={`bg-neutral-950 ${className}`} />;
  }

  if (loading) {
    return <Loading className={`bg-neutral-950 ${className}`} />;
  }

  const hasMultipleChallenges = challengeCount > 1;

  return (
    <div
      className={`relative h-full w-full min-h-0 min-w-0 bg-neutral-950 text-white animate-fadeInFast ${className}`}
    >
      <div className="absolute top-0 right-0 left-0 max-w-[480px] mx-auto md:mt-2 z-[5000] px-1 py-2 bg-black/50 text-sm md:text-md grid grid-cols-[max-content_1fr_max-content] items-center min-w-[250px]">
        {hasMultipleChallenges ? (
          <button onClick={decreaseIndex}>
            <RiArrowDropLeftLine className="w-6 h-6 md:w-8 md:h-8" />
          </button>
        ) : (
          <div className="w-6 h-6" />
        )}
        <div className="text-center">
          <div className="font-bold">{shownChallenge.name}</div>
        </div>
        {hasMultipleChallenges ? (
          <button onClick={increaseIndex}>
            <RiArrowDropRightLine className="w-6 h-6 md:w-8 md:h-8" />
          </button>
        ) : (
          <div className="w-6 h-6" />
        )}
      </div>
      <ChallengeMap challenge={challenges[activeIndex]} fitBounds />
      <NavLink
        to={`/challenges/${challenges[activeIndex].id}`}
        className="absolute bottom-6 right-0 text-black z-[1000] bg-lime-300 font-bold p-3 rounded-md"
      >
        Open challenge
      </NavLink>
    </div>
  );
};

export default ChallengePreview;
