import L from "leaflet";
import { useContext, useMemo } from "react";
import { Marker, Polyline, Popup } from "react-leaflet";
import UserContext from "../../../context/user.context";
import { useRouteCoverage } from "../../../pages/use-route-coverage.hook";
import FitBounds from "../fit-bounds/FitBounds";
import Map from "../map/Map";

const finishMarker = new L.Icon({
  iconUrl: `/chars/Finish-Walk.gif`, // your image path
  iconSize: [40, 40], // size of the icon
  iconAnchor: [20, 35], // point of the icon which will correspond to marker's location
  popupAnchor: [0, -30], // point from which the popup should open relative to the iconAnchor
});

function locationToString(address, place, country) {
  return [address, place, country].filter((str) => !!str).join(", ");
}

const ChallengeMap = ({
  challenge,
  className = "",
  fitBounds = false,
  withZoom = true,
}) => {
  const { user } = useContext(UserContext);
  const { splitPoint, coveredRoute, remainingRoute, route } = useRouteCoverage(
    challenge?.routeCoordinates,
    challenge?.progress
  );

  const foxMarker = useMemo(() => {
    if (!user) {
      return null;
    }

    return new L.Icon({
      iconUrl: `/chars/${user.character}-${
        challenge.isCompleted ? "Idle" : "Run"
      }.gif`, // your image path
      iconSize: [80, 80], // size of the icon
      iconAnchor: [40, 70], // point of the icon which will correspond to marker's location
      popupAnchor: [0, -40], // point from which the popup should open relative to the iconAnchor
    });
  }, [user, challenge]);

  const startLabel = useMemo(
    () =>
      locationToString(
        challenge.startAddress,
        challenge.startPlace,
        challenge.startCountry
      ),
    [challenge]
  );

  const endLabel = useMemo(
    () =>
      locationToString(
        challenge.endAddress,
        challenge.endPlace,
        challenge.endCountry
      ),
    [challenge]
  );

  return (
    <Map className={className} centerPoint={splitPoint} withZoom={withZoom}>
      <>
        {route && route.length > 0 && (
          <Marker position={route[0]}>
            <Popup>{startLabel}</Popup>
          </Marker>
        )}

        {route && route.length > 0 && (
          <Marker position={route[route.length - 1]} icon={finishMarker}>
            <Popup>{endLabel}</Popup>
          </Marker>
        )}
        {splitPoint && (
          <Marker position={splitPoint} icon={foxMarker}>
            <Popup>You are here!</Popup>
          </Marker>
        )}
        {coveredRoute && (
          <Polyline positions={coveredRoute} color={"#bef264"} weight={6} />
        )}
        {remainingRoute && (
          <Polyline positions={remainingRoute} color={"#f7fee7"} />
        )}
        {fitBounds && (
          <FitBounds coordinates={[route[0], route[route.length - 1]]} />
        )}
      </>
    </Map>
  );
};

export default ChallengeMap;
