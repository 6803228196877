import L from "leaflet";
import { useCallback, useEffect, useRef, useState } from "react";
import { drawRoute } from "../../../util/Route.util";

export function useGenerateRoute(start, end, map) {
  const routeRef = useRef();
  const [route, setRoute] = useState();
  const [routeStats, setRouteStats] = useState();
  const [routeLoading, setRouteLoading] = useState();
  const [routeError, setRouteError] = useState();
  const [routePoints, setRoutePoints] = useState();

  const generateRoute = useCallback(
    (startCoordinates, endCoordinates) => {
      setRouteError(null);
      setRoutePoints(null);
      setRouteStats(null);
      setRouteLoading(true);
      if (routeRef.current) {
        try {
          routeRef.current.remove();
        } catch {
          /** */
        }
      }

      const newRoute = drawRoute(
        map,
        [...startCoordinates].reverse(),
        [...endCoordinates].reverse(),
        "#581c87"
      );

      setRoute(newRoute);
      routeRef.current = newRoute;

      newRoute.on("routesfound", function (e) {
        setRouteLoading(false);
        let routes = e.routes;
        let summary = routes[0].summary;

        setRouteStats({
          start: start.label,
          startContext: start.value,
          end: end.label,
          endContext: end.value,
          distanceInMeters: summary.totalDistance,
        });

        if (summary.totalDistance === 0) {
          setRouteError({
            error: {
              status: "LowDistance",
            },
          });
        }

        const points = e.routes[0].coordinates.map(({ lat, lng }) => [
          lat,
          lng,
        ]);
        setRoutePoints(points);

        map.fitBounds([
          L.latLng(startCoordinates[1], startCoordinates[0]),
          L.latLng(endCoordinates[1], endCoordinates[0]),
        ]);
      });

      newRoute.on("routingerror", function (e) {
        setRouteLoading(false);
        setRouteError(e);
      });
    },
    [start, end, map]
  );

  useEffect(() => {
    if (!start || !end) {
      return;
    }

    generateRoute(start.value.coords, end.value.coords);
  }, [generateRoute, start, end]);

  useEffect(() => console.log("LOADING", routeLoading), [routeLoading]);

  return {
    route,
    routeLoading,
    routeError,
    routePoints,
    routeStats,
  };
}
