import { RiMapPinFill, RiMore2Fill } from "react-icons/ri";
import { NavLink } from "react-router-dom";
import {
  printChallengeCompleted,
  printChallengeStart,
} from "../../../util/Challenge.util";
import ChallengeMap from "../challenge-map/ChallengeMap";

const ChallengeListHighlight = ({ challenge, className }) => {
  return (
    <NavLink to={`/challenges/${challenge.id}`}>
      <div className="pb-2 md:px-6 md:py-4 w-full hover:bg-neutral-700">
        <div className={`bg-neutral-900 w-full mx-auto ${className}`}>
          <div className="w-full h-48">
            <ChallengeMap challenge={challenge} fitBounds withZoom={false} />
          </div>
          <div className="p-4">
            <h1 className="text-lime-400 font-bold text-lg">
              {challenge.name}
            </h1>
            <div className="flex gap-4 my-4">
              <div className="flex-initial flex flex-col items-center gap-1">
                <RiMapPinFill className="w-6 h-6" />
                <RiMore2Fill />
                <RiMapPinFill className="w-6 h-6" />
              </div>
              <div className="flex-1 text-sm flex flex-col justify-between">
                <p className="py-1">{challenge.start}</p>
                <p className="py-1">{challenge.end}</p>
              </div>
            </div>
            <small className="text-xs text-neutral-400">
              {!challenge.isCompleted
                ? printChallengeStart(challenge)
                : printChallengeCompleted(challenge)}
            </small>
          </div>
        </div>
      </div>
    </NavLink>
  );
};

export default ChallengeListHighlight;
