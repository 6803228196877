import { useCallback, useContext } from "react";
import { useForm } from "react-hook-form";
import { RiCheckFill } from "react-icons/ri";
import { useNotificationContext } from "../../../context/notification.context";
import UserContext from "../../../context/user.context";
import { useUpdateMe } from "../../../hooks/use-update-me.hook";

const DailyStepsSettings = () => {
  const { user } = useContext(UserContext);
  const { notify } = useNotificationContext();
  const { register, handleSubmit } = useForm({
    defaultValues: {
      dailyStepGoal: user.dailyStepGoal,
    },
  });
  const updateMe = useUpdateMe();

  const submit = useCallback(
    async ({ dailyStepGoal }) => {
      try {
        await updateMe({ dailyStepGoal });
        notify("success", {
          title: "Settings saved.",
          description: "Your daily step goal has been updated.",
          duration: 3000,
        });
      } catch (err) {
        console.error(err);
        notify("error", {
          title: "Failed to save your settings.",
          description: "An unknown error occurred. Please try again.",
          duration: 3000,
        });
      }
    },
    [updateMe, notify]
  );

  return (
    <form
      onSubmit={handleSubmit(submit)}
      className="flex gap-4 relative h-full items-center"
    >
      <label className="flex-1">
        <input
          className="oc-input"
          name="dailyStepGoal"
          type="number"
          {...register(`dailyStepGoal`, { required: true })}
        />
      </label>
      <button
        type="submit"
        className="bg-lime-300 hover:bg-lime-400 text-black p-2 rounded-md font-bold h-full aspect-square flex items-center justify-center"
      >
        <RiCheckFill className="w-5 h-5" />
      </button>
    </form>
  );
};

export default DailyStepsSettings;
