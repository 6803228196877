const DataPrivacyStatement = () => {
  return (
    <div className="oc-container">
      <div className="oc-content prose prose-sm sm:prose lg:prose-lg xl:prose-2xl mx-auto">
        <h2 className="text-2xl font-bold">Data Privacy Statement</h2>
        <p className="text-md mb-2">
          This privacy statement ("Statement") applies to the treatment of
          personally identifiable information submitted by, or otherwise
          obtained from, you in connection with the associated application
          ("Application"). The Application is provided by Benjamin Adam (the
          "Provider"). By using or otherwise accessing the Application, you
          acknowledge that you accept the practices and policies outlined in
          this Statement.
        </p>

        <h3 className="text-xl font-bold mt-4">
          What personal information does the Provider collect?
        </h3>
        <p className="text-md mb-2">
          We collect the following types of information from our users:
        </p>
        <p className="text-md mb-2">
          Personal Information You Provide to Us: We may receive and store any
          information you submit to the Application (or otherwise authorize us
          to obtain). The types of personal information collected may include
          your full name, email address, device ID, location information, and
          any other information necessary for us to provide the Application
          services.
        </p>
        <p className="text-md mb-2">
          By linking your health fitness provider you agree that we synchronize
          data concerning your steps as well as the estimated distance you
          covered in a specific period of time to provide the Application
          services.
        </p>

        <h3 className="text-xl font-bold mt-4">
          How does the Provider use the information it collects?
        </h3>
        <p className="text-md mb-2">
          The Provider uses the information described in this Statement (i) to
          operate, maintain, enhance and provide all features of the
          Application, (ii) to provide services and information that you
          request, (iii) to respond to comments and questions and provide
          customer service, (iv) to send you notifications, and (v) to track and
          analyze Application usage.
        </p>

        <h3 className="text-xl font-bold mt-4">
          Will the Provider share any of the personal information it receives?
        </h3>
        <p className="text-md mb-2">
          We do not rent, sell, or share your personal information to third
          parties unless we have your permission or the law requires us to do
          so.
        </p>

        <h3 className="text-xl font-bold mt-4">
          Changes to this privacy statement
        </h3>
        <p className="text-md mb-2">
          We may amend this privacy statement from time to time. Use of
          information we collect is subject to the Privacy Statement in effect
          at the time such information is used. If we make changes in the way we
          use personal information, we will notify you by posting an
          announcement on our website or sending you an email.
        </p>

        <h3 className="text-xl font-bold mt-4">Contact us</h3>
        <p className="text-md mb-2">
          If you have any questions or concerns regarding privacy on our
          Website, please send us a detailed message at
          m.benjamin.adam@gmail.com. We will make every effort to resolve your
          concerns.
        </p>
      </div>
    </div>
  );
};

export default DataPrivacyStatement;
