import { useCallback, useContext, useState } from "react";
import { RiEditCircleFill } from "react-icons/ri";
import UserContext from "../../../context/user.context";
import CharacterSelection from "../character-selection/CharacterSelection";

const ProfileHeader = ({ className }) => {
  const { user } = useContext(UserContext);
  const [editImage, setEditImage] = useState(false);
  const toggleEditImage = useCallback(() => setEditImage((curr) => !curr), []);

  return (
    <header className={`h-52 w-full bg-neutral-950 relative ${className}`}>
      <div className="absolute inset-0">
        <img
          className="absolute inset-0 w-full h-full object-cover bg-repeat-x rendering-pixelated"
          src="/img/running-forest-day-wide.gif"
          alt="Header background"
        />
        {user && (
          <img
            className="w-11 h-11 md:w-12 md:h-12 rendering-pixelated absolute left-1/2 top-1/2 -translate-x-1/2"
            src={`/chars/${user.character}-Run.gif`}
            alt={user.character}
          />
        )}
        {editImage && (
          <CharacterSelection onCharacterSelected={toggleEditImage} />
        )}
      </div>

      <div className="absolute left-6 md:left-12 -bottom-7 md:-bottom-10 w-28 h-28 md:w-40 md:h-40 bg-neutral-900 rounded-full">
        {user && (
          <img
            className="w-28 h-28 md:w-40 md:h-40 rendering-pixelated"
            src={`/chars/${user.character}-Idle.gif`}
            alt={user.character}
          />
        )}
        <button
          className="absolute right-0 bottom-0 p-1"
          onClick={toggleEditImage}
        >
          <RiEditCircleFill className="w-6 h-6 md:w-8 md:h-8 text-neutral-400 opacity-75" />
        </button>
      </div>
    </header>
  );
};

export default ProfileHeader;
