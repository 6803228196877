import { RiMapPinFill } from "react-icons/ri";
import { NavLink } from "react-router-dom";
import {
  printChallengeCompleted,
  printChallengeProgress,
  printChallengeStart,
} from "../../../util/Challenge.util";

const ChallengeListItem = ({ challenge }) => {
  const { id, name } = challenge;

  return (
    <li>
      <NavLink
        to={`/challenges/${id}`}
        key={id}
        className="flex items-center hover:bg-neutral-700 py-2 md:py-4 md:px-6 gap-4 md:gap-6"
      >
        <div
          className={`p-3 md:p-6 bg-neutral-900 ${
            challenge.isCompleted ? "text-lime-400" : "text-white"
          }`}
        >
          <RiMapPinFill className="w-6 h-6 md:w-8 md:h-8" />
        </div>
        <div className="flex-1 min-w-0">
          <h2 className="text-md text-lime-400 font-bold block overflow-hidden text-ellipsis whitespace-nowrap">
            {name}
          </h2>
          <small className="hidden md:block text-xs text-white">
            {printChallengeProgress(challenge)}
          </small>
          <small className="text-xs text-neutral-400 block">
            {challenge.isCompleted
              ? printChallengeCompleted(challenge)
              : printChallengeStart(challenge)}
          </small>
        </div>
      </NavLink>
    </li>
  );
};

export default ChallengeListItem;
