import PasswordResetForm from "../components/authentication/password-reset-form/PasswordResetForm";

const ResetPassword = () => {
  return (
    <div className="oc-content pt-0 px-0 flex items-center justify-center">
      <div className="bg-neutral-900 p-8 w-full max-w-sm">
        <PasswordResetForm />
      </div>
    </div>
  );
};

export default ResetPassword;
