import React from "react";
import CountUp from "react-countup";

const Bar = ({ percentage, index, label = "" }) => {
  return (
    <div className="bg-black w-10 md:w-14 flex flex-col p-1 md:p-2 gap-1 justify-end relative group">
      <div className="w-full h-full flex flex-col justify-end relative">
        <div
          className={`absolute bottom-2 left-0 right-0 flex justify-center items-end -rotate-45 md:rotate-0 text-xs text-center group-hover:font-bold text-white`}
        >
          <CountUp
            start={0}
            end={percentage}
            duration={2}
            suffix="%"
            delay={0.5 + (index * 150) / 1000}
            useEasing
          />
        </div>

        <div
          className={`bg-lime-300 group-hover:bg-lime-400 transition-all duration-1000 ease-in-out overflow-hidden`}
          style={{
            height: `${percentage}%`,
            transitionDelay: `${index * 150}ms`,
            filter: `saturate(${percentage / 100})`,
          }}
        >
          <div
            className={`absolute z-30 bottom-2 left-0 right-0 flex justify-center items-end -rotate-45 md:rotate-0 text-xs text-center group-hover:font-bold ${
              percentage >= 100 ? "text-black" : "text-black font-medium"
            }`}
          >
            <CountUp
              start={0}
              end={percentage}
              duration={2}
              suffix="%"
              delay={0.5 + (index * 150) / 1000}
              useEasing
            />
          </div>
        </div>
      </div>

      <div className="text-xs text-center w-full overflow-hidden text-ellipsis rounded-md">
        {label}
      </div>
    </div>
  );
};

export default Bar;
