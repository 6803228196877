import { useCallback, useContext, useState } from "react";
import {
  RiCloseFill,
  RiDashboardFill,
  RiGitRepositoryFill,
  RiMapPinUserFill,
  RiMenu2Fill,
  RiSettings4Fill,
  RiTreasureMapFill,
} from "react-icons/ri";
import { NavLink } from "react-router-dom";
import UserContext from "../../context/user.context";

const NavigationButton = ({ children, onClick }) => {
  return (
    <button
      className="block md:hidden fixed top-0 left-0 text-white p-3 text-2xl z-[15000]"
      onClick={onClick}
    >
      {children}
    </button>
  );
};

const NavigationOpenButton = ({ onClick }) => {
  return (
    <NavigationButton onClick={onClick}>
      <RiMenu2Fill className="w-8 h-8" />
    </NavigationButton>
  );
};

const NavigationCloseButton = ({ onClick }) => {
  return (
    <NavigationButton onClick={onClick}>
      <RiCloseFill className="w-8 h-8" />
    </NavigationButton>
  );
};

const Navigation = () => {
  const { user } = useContext(UserContext);
  const [isOpen, setOpen] = useState(false);

  const classNameFunc = useCallback(
    ({ isActive }) =>
      `p-2 md:p-3 mx-auto flex items-center w-full md:w-auto gap-4 md:gap-0 flex-grow-0 flex-shrink-0 overflow-hidden ${
        isActive
          ? "bg-lime-300 active font-bold text-black hover:bg-lime-300"
          : "text-neutral-100 hover:bg-black"
      }`,
    []
  );

  return (
    user && (
      <>
        <img
          src="/icons/icon-192x192.png"
          className="w-10 h-10 fixed top-2 left-1/2 -translate-x-1/2 rounded-full z-[17000] block md:hidden rendering-pixelated"
          alt="Logo"
        />
        <NavigationOpenButton onClick={() => setOpen(true)} />
        <div className="block md:hidden h-16"></div>

        <nav
          className={`absolute inset-0 z-[15000] ${
            isOpen ? "translate-x-0" : "-translate-x-full"
          } transition-transform md:translate-x-0 md:relative flex gap-y-2 flex-col bg-neutral-950 md:h-full p-4 pt-16 md:pt-4`}
        >
          <NavigationCloseButton onClick={() => setOpen(false)} />

          {/** Upper items **/}
          <div className="flex-1 flex flex-col gap-4 md:gap-2">
            <NavLink
              to={"/"}
              className={`${classNameFunc(
                false
              )} hidden md:flex md:mb-16 hover:bg-transparent`}
            >
              <img
                src="/icons/icon-192x192.png"
                className="hidden md:block w-16 h-16 fixed top-2 left-1/2 -translate-x-1/2 rounded-full z-[17000]"
                alt="Logo"
              />
            </NavLink>

            <NavLink
              onClick={() => setOpen(false)}
              to={"/"}
              className={classNameFunc}
            >
              <RiDashboardFill className="w-8 h-8" />
              <div className="text-xl md:hidden">Dashboard</div>
            </NavLink>
            <NavLink
              onClick={() => setOpen(false)}
              to={"/challenges"}
              className={classNameFunc}
            >
              <RiTreasureMapFill className="w-8 h-8" />
              <div className="text-xl md:hidden">Challenges</div>
            </NavLink>
          </div>

          {/** End items **/}
          <div className="flex-initial flex flex-col gap-4 md:gap-2">
            <NavLink
              onClick={() => setOpen(false)}
              to={"/profile"}
              className={classNameFunc}
            >
              <RiMapPinUserFill className="w-8 h-8" />
              <div className="text-xl md:hidden">Profile</div>
            </NavLink>

            {/** Settings **/}
            <NavLink
              onClick={() => setOpen(false)}
              to={"/settings"}
              className={classNameFunc}
            >
              <RiSettings4Fill className="w-8 h-8" />
              <div className="text-xl md:hidden">Settings</div>
            </NavLink>
            {/** Settings **/}
            <NavLink
              onClick={() => setOpen(false)}
              to={"/legal"}
              className={classNameFunc}
            >
              <RiGitRepositoryFill className="w-8 h-8" />
              <div className="text-xl md:hidden">Legal statement</div>
            </NavLink>
          </div>
        </nav>
      </>
    )
  );
};

export default Navigation;
