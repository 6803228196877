import markerIcon from "leaflet/dist/images/marker-icon.png";
import markerIconShadow from "leaflet/dist/images/marker-shadow.png";

import L from "leaflet";
import "leaflet-routing-machine";
import { useEffect } from "react";
import {
  MapContainer,
  TileLayer,
  ZoomControl,
  useMapEvents,
} from "react-leaflet";

const DEFAULT_POSITION_START = [51.2172994, 6.4458812];

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: markerIcon,
  iconUrl: markerIcon,
  shadowUrl: markerIconShadow,
});

/*
const runningIcon = new L.Icon({
  iconUrl: "/icons/mario.gif",
  iconRetinaUrl: "/icons/mario.gif",
  shadowUrl: null,
  icon: null,
  iconSize: new L.Point(48, 48),
  iconAnchor: new L.Point(24, 24),
});
*/

function RoutingComponent({ onMapChange, children }) {
  const map = useMapEvents({
    click() {},
    drag() {},
  });

  useEffect(() => {
    onMapChange && onMapChange(map);
  }, [onMapChange, map]);

  return null;
}

function Map({
  className,
  onMapChange,
  centerPoint,
  children,
  withZoom = true,
}) {
  return (
    <MapContainer
      className={`h-full w-full bg-black ${className}`}
      center={centerPoint || DEFAULT_POSITION_START}
      zoom={13}
      zoomControl={false}
    >
      {withZoom && <ZoomControl position="bottomleft" />}
      <div className="flex">
        <TileLayer
          url="https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
        />

        <RoutingComponent onMapChange={onMapChange} />
      </div>
      {children}
    </MapContainer>
  );
}

export default Map;
