import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";
import Navigation from "./components/navigation/Navigation";
import Notifications from "./components/notification/Notifications";
import DataSourceSettings from "./components/settings/data-source/DataSourceSettings";
import NotificationSettings from "./components/settings/notifications/NotificationSettings";
import PersonalSettings from "./components/settings/personal/PersonalSettings";
import ErrorBoundary from "./components/shared/error-boundary/ErrorBoundary";
import { NotificationProvider } from "./context/notification.context";
import UserProvider from "./context/user.context.provider";
import AuthenticationPage from "./pages/Authentication.page";
import Challenge from "./pages/Challenge.page";
import ChallengeNew from "./pages/ChallengeNew.page";
import Challenges from "./pages/Challenges.page";
import Dashboard from "./pages/Dashboard.page";
import DataPrivacyStatement from "./pages/DataPrivacyStatement.page";
import Imprint from "./pages/Imprint.page";
import LegalStatement from "./pages/LegalStatement.page";
import Profile from "./pages/Profile.page";
import ResetPassword from "./pages/ResetPassword.page";
import Settings from "./pages/Settings.page";
import TermsOfService from "./pages/TermsOfService.page";
import VerifyEmailPage from "./pages/VerifyEmail.page";

function App() {
  return (
    <ErrorBoundary>
      <NotificationProvider>
        <BrowserRouter>
          <UserProvider>
            <div className="flex flex-col md:flex-row h-full w-full bg-black">
              <Navigation className="flex-grow-0 flex-shrink-0" />
              <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route path="/auth" element={<AuthenticationPage />} />
                <Route path="/signin" element={<Navigate to="/auth" />} />
                <Route
                  path="/register"
                  element={<Navigate to="/auth?register=true" />}
                />
                <Route path="/reset-password" element={<ResetPassword />} />
                <Route path="/challenges" element={<Challenges />} />
                <Route
                  path="/challenges/:challengeId"
                  element={<Challenge />}
                />
                <Route path="/challenges/new" element={<ChallengeNew />} />
                <Route path="/settings" element={<Settings />}>
                  <Route path="data-source" element={<DataSourceSettings />} />
                  <Route path="personal" element={<PersonalSettings />} />
                  <Route
                    path="notifications"
                    element={<NotificationSettings />}
                  />
                </Route>
                <Route path="/profile" element={<Profile />} />
                <Route path="/verify" element={<VerifyEmailPage />} />

                <Route path="/legal" element={<LegalStatement />} />
                <Route path="/imprint" element={<Imprint />} />
                <Route
                  path="/data-privacy"
                  element={<DataPrivacyStatement />}
                />
                <Route path="/terms-of-service" element={<TermsOfService />} />
              </Routes>
              <Outlet className="flex-1" />
              <Notifications />
            </div>
          </UserProvider>
        </BrowserRouter>
      </NotificationProvider>
    </ErrorBoundary>
  );
}

export default App;
