import { useCallback, useContext } from "react";
import UserContext from "../context/user.context";
import axiosInstance from "../util/Axios.util";

export function useUnlinkProvider() {
  const { updateUser } = useContext(UserContext);

  return useCallback(async () => {
    return axiosInstance.post(`/api/user/unlink`).then((response) => {
      updateUser(response.data.user);
    });
  }, [updateUser]);
}
