import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { useNotificationContext } from "../../../context/notification.context";
import axiosInstance from "../../../util/Axios.util";
import { unknownError } from "../../../util/Notification.util";
import Logo from "../../shared/logo/Logo";

const ForgotPasswordForm = () => {
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const { notify } = useNotificationContext();
  const { register, handleSubmit } = useForm({
    defaultValues: {
      email: null,
    },
  });

  const toLogin = useCallback(() => {
    searchParams.set("context", "login");
    setSearchParams(searchParams);
  }, [searchParams, setSearchParams]);

  const submit = useCallback(
    async ({ email }) => {
      try {
        setLoading(true);
        await axiosInstance.post("/auth/reset-password-request", { email });
        notify("success", {
          title: "Password reset request submitted",
          description:
            "A mail with a link to reset your password will be sent to you if the email can be found in our system!",
        });
        toLogin();
      } catch (err) {
        console.error(err);
        notify(...unknownError);
      } finally {
        setLoading(false);
      }
    },
    [toLogin, notify]
  );

  return (
    <form onSubmit={handleSubmit(submit)} className="flex flex-col gap-4">
      <Logo className="w-32 h-32 mx-auto" />
      <label>
        <div className="ml-2 text-sm">E-Mail address</div>
        <input
          className="oc-input"
          name="email"
          type="email"
          disabled={loading}
          {...register(`email`, { required: true })}
        />
      </label>
      <button className="bg-lime-300 hover:bg-lime-400 text-black p-2 rounded-md font-bold">
        Continue
      </button>
      <button
        type="button"
        className="block p-2 w-full text-center"
        disabled={loading}
        onClick={toLogin}
      >
        Back to signing in
      </button>
    </form>
  );
};

export default ForgotPasswordForm;
