// Notification.js
import React, { useRef } from "react";
import { RiCloseFill } from "react-icons/ri";
import { useNotificationContext } from "../../context/notification.context";

const Notification = ({ id, type, title, description, duration = 5000 }) => {
  const { dismiss } = useNotificationContext();
  const nodeRef = useRef(null);

  // Dismiss the notification after `duration` milliseconds
  React.useEffect(() => {
    const timer = setTimeout(() => dismiss(id), duration);
    return () => clearTimeout(timer);
  }, [id, duration, dismiss]);

  return (
    <div
      ref={nodeRef}
      className={`relative block text-left p-4 shadow-lg rounded mt-4 ${
        type === "error"
          ? "bg-red-500 text-white"
          : "bg-lime-400 text-black mt-4"
      } w-full md:w-80`}
    >
      <h4 className="font-bold">{title}</h4>
      {description && <p className="mt-2 text-sm">{description}</p>}
      <button
        className="absolute top-0 right-0 w-8 h-8 flex items-center justify-center text-2xl leading-none"
        onClick={() => dismiss(id)}
      >
        <RiCloseFill />
      </button>
    </div>
  );
};

export default Notification;
