import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../util/Axios.util";

export function useLogout() {
  const navigate = useNavigate();

  return useCallback(async () => {
    return axiosInstance.post(`/auth/logout`).then((response) => {
      localStorage.removeItem("token");
      navigate("/auth?logout=true");
      delete axiosInstance.defaults.headers.common["Authorization"];
    });
  }, [navigate]);
}
