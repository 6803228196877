import React, { useCallback, useContext, useState } from "react";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { useNotificationContext } from "../../../context/notification.context";
import UserContext from "../../../context/user.context";
import { useUpdateMe } from "../../../hooks/use-update-me.hook";

const ButtonBarField = React.forwardRef(
  ({ name, options, onChange, type = "button", disabled }, ref) => {
    const { methods } = useFormContext();
    const { watch } = methods;
    const currentValue = watch(name);

    return (
      <div className="w-full flex" ref={ref}>
        {options.map(({ label, value }) => (
          <button
            key={value}
            name={name}
            type={type}
            className={`flex-1 p-2 cursor-pointer disabled:opacity-25 ${
              value === currentValue
                ? "bg-lime-400 text-black font-bold"
                : "bg-neutral-600 text-white"
            }`}
            onClick={(e) => {
              methods.setValue(name, value);
              onChange(e);
            }}
            disabled={disabled}
          >
            {label}
          </button>
        ))}
      </div>
    );
  }
);

const ButtonBar = React.forwardRef(
  ({ name, rules, options, type, disabled }, ref) => {
    const { methods } = useFormContext();

    return (
      <ButtonBarField
        disabled={disabled}
        options={options}
        {...methods.register(name, rules)}
        type={type}
      />
    );
  }
);

const ChallengeEmailInterval = () => {
  const { user } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const methods = useForm({
    defaultValues: {
      challengeNotificationInterval: user.challengeNotificationInterval,
    },
  });
  const updateMe = useUpdateMe();
  const { notify } = useNotificationContext();

  const submit = useCallback(
    async ({ challengeNotificationInterval }) => {
      if (
        challengeNotificationInterval === user.challengeNotificationInterval
      ) {
        return;
      }

      try {
        setLoading(true);
        await updateMe({
          challengeNotificationInterval,
        });
        methods.reset({ challengeNotificationInterval });

        let message;
        if (challengeNotificationInterval === "none") {
          message = `You won't be notified about your progress anymore!`;
        } else {
          message = `You'll be notified ${challengeNotificationInterval} from now on.`;
        }
        notify("success", {
          title: "Settings saved.",
          description: message,
          duration: 3000,
        });
      } catch (err) {
        notify("error", {
          title: "Failed to save your settings.",
          description: "An unknown error occurred. Please try again.",
          duration: 3000,
        });
        console.error(err);
      } finally {
        setLoading(false);
      }
    },
    [user, updateMe, methods, notify]
  );

  return (
    <FormProvider methods={methods}>
      <form
        onSubmit={methods.handleSubmit(submit)}
        className="flex gap-4 relative h-full"
      >
        <ButtonBar
          name="challengeNotificationInterval"
          rules={{ required: true }}
          type="submit"
          disabled={loading}
          options={[
            { label: "None", value: "none" },
            { label: "Daily", value: "daily" },
            { label: "Weekly", value: "weekly" },
          ]}
        />
      </form>
    </FormProvider>
  );
};

export default ChallengeEmailInterval;
