import { useCallback } from "react";

const CharacterSelectionTile = ({
  character,
  animation,
  className = "",
  selected,
  onClick,
}) => {
  const handleClick = useCallback(
    () => onClick(character),
    [onClick, character]
  );

  return (
    <div
      className={`aspect-square border-4 ${
        selected
          ? "bg-neutral-800 border-purple-800"
          : "bg-neutral-900 border-transparent cursor-pointer"
      } ${className}`}
      onClick={handleClick}
      tabIndex="0"
    >
      <img
        className="object-contain rendering-pixelated"
        src={`/chars/${character}-${animation}.gif`}
        alt={`${character} ${animation}`}
      />
    </div>
  );
};

export default CharacterSelectionTile;
