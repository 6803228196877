import { useEffect, useState } from "react";
import { useNotificationContext } from "../context/notification.context";
import axiosInstance from "../util/Axios.util";
import { unknownError } from "../util/Notification.util";

export function useDailySteps(days) {
  const { notify } = useNotificationContext();
  const [dailySteps, setDailySteps] = useState();
  const [loading, setLoading] = useState();
  const [error, setError] = useState();

  useEffect(() => {
    const fetchDailySteps = async () => {
      try {
        setLoading(true);
        const response = await axiosInstance.get(`/api/fit/daily-steps`, {
          days,
        });
        setDailySteps(response.data);
      } catch (error) {
        console.warn("Error fetching daily steps:", error);
        // TODO: Leave this to the component?
        notify(...unknownError);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchDailySteps();
  }, [days, notify]);

  return {
    dailySteps,
    loading,
    error,
  };
}
