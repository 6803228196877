import React, { useEffect, useRef } from "react";
import CountUp from "react-countup";

function Circular({ percentage, stroke = "#bef264" }) {
  const radius = 50;
  const circumference = 2 * Math.PI * radius;
  const initialOffset = circumference;
  const strokeDashoffset = ((100 - percentage) / 100) * circumference;

  const animatedCircleRef = useRef(null);

  useEffect(() => {
    const animatedCircle = animatedCircleRef.current;
    if (animatedCircle) {
      animatedCircle.style.transition = "stroke-dashoffset 2s ease 1s";
      animatedCircle.style.strokeDashoffset = `${strokeDashoffset}px`;
    }
  }, [strokeDashoffset]);

  return (
    <div className="relative w-full h-full aspect-square">
      <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
        <svg className="w-full h-full text-neutral-800" viewBox="0 0 120 120">
          <circle
            cx="60"
            cy="60"
            r={radius}
            strokeWidth="16"
            fill="none"
            stroke="currentColor"
          />
          <circle
            ref={animatedCircleRef}
            cx="60"
            cy="60"
            r={radius}
            strokeWidth="16"
            stroke={stroke}
            fill="none"
            strokeLinecap="butt"
            strokeDasharray={circumference}
            strokeDashoffset={initialOffset}
            style={{ transform: "rotate(-90deg)", transformOrigin: "center" }}
          />
        </svg>
        <div className="absolute inset-0 flex items-center justify-center text-sm md:text-4xl font-semibold">
          <CountUp
            start={0}
            end={percentage}
            duration={2}
            suffix="%"
            delay={1}
            useEasing
          />
        </div>
      </div>
    </div>
  );
}

export default Circular;
