const LegalStatement = () => {
  return (
    <div className="oc-container mb-8">
      <div className="oc-content bg-neutral-950 prose prose-sm sm:prose lg:prose-lg xl:prose-2xl mx-auto">
        <h2 className="text-lime-400 text-3xl font-bold mb-3">
          Data Privacy Statement
        </h2>
        <p className="text-neutral-300 text-md mb-2">
          This privacy statement ("Statement") applies to the treatment of
          personally identifiable information submitted by, or otherwise
          obtained from, you in connection with the associated application
          ("Application"). The Application is provided by Benjamin Adam (the
          "Provider"). By using or otherwise accessing the Application, you
          acknowledge that you accept the practices and policies outlined in
          this Statement.
        </p>

        <h3 className="text-xl font-semibold mb-1 mt-4">
          What personal information does the Provider collect?
        </h3>
        <p className="text-neutral-300 text-md mb-2">
          We collect the following types of information from our users:
        </p>
        <p className="text-neutral-300 text-md mb-2">
          Personal Information You Provide to Us: We may receive and store any
          information you submit to the Application (or otherwise authorize us
          to obtain). The types of personal information collected may include
          your full name, email address, device ID, location information, and
          any other information necessary for us to provide the Application
          services.
        </p>
        <p className="text-neutral-300 text-md mb-2">
          By linking your health fitness provider you agree that we synchronize
          data concerning your steps as well as the estimated distance you
          covered in a specific period of time to provide the Application
          services.
        </p>

        <h3 className="text-xl font-semibold mb-1 mt-4">
          How does the Provider use the information it collects?
        </h3>
        <p className="text-neutral-300 text-md mb-2">
          The Provider uses the information described in this Statement (i) to
          operate, maintain, enhance and provide all features of the
          Application, (ii) to provide services and information that you
          request, (iii) to respond to comments and questions and provide
          customer service, (iv) to send you notifications, and (v) to track and
          analyze Application usage.
        </p>

        <h3 className="text-xl font-semibold mb-1 mt-4">
          Will the Provider share any of the personal information it receives?
        </h3>
        <p className="text-neutral-300 text-md mb-2">
          We do not rent, sell, or share your personal information to third
          parties unless we have your permission or the law requires us to do
          so.
        </p>

        <h3 className="text-xl font-semibold mb-1 mt-4">
          Changes to this privacy statement
        </h3>
        <p className="text-neutral-300 text-md mb-2">
          We may amend this privacy statement from time to time. Use of
          information we collect is subject to the Privacy Statement in effect
          at the time such information is used. If we make changes in the way we
          use personal information, we will notify you by posting an
          announcement on our website or sending you an email.
        </p>

        <h3 className="text-xl font-semibold mb-1 mt-4">Contact us</h3>
        <p className="text-neutral-300 text-md mb-2">
          If you have any questions or concerns regarding privacy on our
          Website, please send us a detailed message at
          m.benjamin.adam@gmail.com. We will make every effort to resolve your
          concerns.
        </p>

        <h2 className="text-lime-400 text-3xl mt-8 font-bold mb-3">
          Terms of Service
        </h2>
        <p className="text-neutral-300 text-md mb-2">
          These terms and conditions ("Terms") govern the use of PixelPace
          ("Application") provided by Benjamin Adam ("Provider"). Please read
          these Terms carefully. By using the Application, you agree to these
          Terms. If you don't agree to these Terms, do not use the Application.
        </p>

        <h3 className="text-xl font-semibold mb-1 mt-4">
          Use of the Application
        </h3>
        <p className="text-neutral-300 text-md mb-2">
          You may use the Application for personal, non-commercial purposes, and
          subject to these Terms, you are granted a limited, personal,
          non-transferable, and non-exclusive license to use the Application.
        </p>

        <h3 className="text-xl font-semibold mb-1 mt-4">Content</h3>
        <p className="text-neutral-300 text-md mb-2">
          All software, data, text, images, sounds, videos, and other content
          made available through the Application ("Content") are owned by the
          Provider or its licensors. You agree to not use or disclose the
          Content except as expressly permitted by these Terms.
        </p>

        <h3 className="text-xl font-semibold mb-1 mt-4">Privacy</h3>
        <p className="text-neutral-300 text-md mb-2">
          Your use of the Application is subject to the Provider's Privacy
          Statement, which is incorporated into these Terms by this reference.
        </p>

        <h3 className="text-xl font-semibold mb-1 mt-4">Prohibited Use</h3>
        <p className="text-neutral-300 text-md mb-2">
          You agree to use the Application only for its intended purpose. You
          must use the Application in compliance with all privacy, data
          protection, intellectual property, and other applicable laws. The
          following uses of the Application are prohibited: (i) any use that
          interferes or disrupts the Application or the servers or networks
          connected to the Application; (ii) unauthorized access to or use of
          data, systems, or networks, including any attempt to probe, scan, or
          test the vulnerability of the Application or to breach security or
          authentication measures; and (iii) use of the Application to send
          unsolicited email, including without limitation promotions or
          advertisements for products or services.
        </p>

        <h3 className="text-xl font-semibold mb-1 mt-4">
          Modifications to the Application
        </h3>
        <p className="text-neutral-300 text-md mb-2">
          The Provider reserves the right to modify or discontinue, temporarily
          or permanently, the Application or any features or portions thereof
          without prior notice.
        </p>

        <h3 className="text-xl font-semibold mb-1 mt-4">Termination</h3>
        <p className="text-neutral-300 text-md mb-2">
          The Provider may terminate your access to and use of the Application
          at our sole discretion, at any time, and without notice to you.
        </p>

        <h3 className="text-xl font-semibold mb-1 mt-4">Contact</h3>
        <p className="text-neutral-300 text-md mb-2">
          If you have any questions about these Terms, please contact the
          Provider at m.benjamin.adam@gmail.com.
        </p>

        <h2 className="text-lime-400 text-3xl mt-8 font-bold mb-3">Imprint</h2>
        <p className="text-neutral-300 text-md mb-2">
          PixelPace ("Application") is provided by Benjamin Adam ("Provider").
        </p>

        <h3 className="text-xl font-semibold mb-1 mt-4">Contact Information</h3>
        <p className="text-neutral-300 text-md mb-2">
          You can contact the Provider at:
        </p>
        <p className="text-neutral-300 text-md mb-2">
          Eickener Str., 41063 Mönchengladbach, Germany
        </p>
        <p className="text-neutral-300 text-md mb-2">
          Email: m.benjamin.adam@gmail.com
        </p>

        <h3 className="text-xl font-semibold mb-1 mt-4">Legal Disclosure</h3>
        <p className="text-neutral-300 text-md mb-2">
          Information in accordance with Section 5 TMG (in countries where this
          is applicable).
        </p>

        <h3 className="text-xl font-semibold mb-1 mt-4">
          Person responsible for content
        </h3>
        <p className="text-neutral-300 text-md mb-2">
          In accordance with Section 55, Para. 2 of the German Interstate
          Broadcasting Agreement (RStV) (if applicable): Benjamin Adam
        </p>
      </div>
    </div>
  );
};

export default LegalStatement;
