const GoogleFitIcon = () => {
  return (
    <div className="w-12 h-12 flex items-center justify-center bg-white rounded-[2px]">
      <img
        src="/img/google-fit.png"
        className="w-6 h-6"
        alt="Google Fit Logo"
      />
    </div>
  );
};

export default GoogleFitIcon;
