import { useCallback, useContext, useEffect } from "react";
import { RiCheckFill } from "react-icons/ri";
import { useSearchParams } from "react-router-dom";
import { useNotificationContext } from "../../../context/notification.context";
import UserContext from "../../../context/user.context";
import axiosInstance from "../../../util/Axios.util";
import GoogleFitButton from "../google-fit-button/GoogleFitButton";
import GoogleFitIcon from "../google-fit-icon/GoogleFitIcon";
import { UnlinkFitnessProviderButton } from "../unlink-fitness-provider-button/UnlinkFitnessProviderButton";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const GoogleFitConnected = () => {
  return (
    <div className="flex items-center w-full h-12 gap-2">
      <GoogleFitIcon />{" "}
      <div className="flex-1 font-bold flex items-center justify-center">
        Google Fit is connected
      </div>
      <RiCheckFill className="w-8 h-8" />
    </div>
  );
};

const DataSourceSettings = () => {
  const { user, resetUser } = useContext(UserContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const { notify } = useNotificationContext();

  useEffect(() => {
    const success = searchParams.get("success");
    if (success === "true") {
      // Store the token in your preferred state management solution
      // e.g., React Context, Redux, or even localStorage
      // This example uses localStorage
      notify("success", {
        title: "Data source connected.",
        description: "Your health data will be synced from now on!",
        duration: 3000,
      });
      searchParams.delete("success");
      setSearchParams(searchParams);
    }
  }, [searchParams, setSearchParams, notify]);

  const invokeGoogleFlow = useCallback(async () => {
    try {
      const response = await axiosInstance.get(`/auth/google/state`, {
        withCredentials: true,
      });
      const { state } = response.data;
      resetUser();
      window.location.href = `${BACKEND_URL}/auth/google?state=${state}`;
    } catch (error) {
      // TODO ?
      console.error("Failed to start Google auth", error);
    }
  }, [resetUser]);

  return (
    <div className="w-full h-full md:py-4">
      <div className="max-w-[520px] mx-auto">
        <h1 className="w-full text-3xl mb-4">Health data source</h1>

        <div className="p-4 my-4 bg-neutral-900 md:bg-neutral-950">
          {user.provider !== "google_fit" && (
            <GoogleFitButton className="w-full" onClick={invokeGoogleFlow} />
          )}
          {user.provider === "google_fit" && <GoogleFitConnected />}
          <UnlinkFitnessProviderButton />
        </div>

        <p className="mb-4 text-sm text-neutral-300">
          Your data source provides the basis for your PixelPace challenges!
        </p>
        <p className="mb-4 text-sm text-neutral-300">
          Therefore the amount of steps you made as well as the estimated
          distance you traveled will be synced. The data is used exclusively for
          your PixelPace features. Be sure - it's not going anywhere else! :)
        </p>
      </div>
    </div>
  );
};

export default DataSourceSettings;
