import { useMemo } from "react";
import { useWeeklySteps } from "../../../../hooks/use-weekly-steps.hook";
import DashboardTile from "../../dashboard-tile/DashboardTile";
import Circular from "../circular/Circular";

const GOAL = 60000;

const WeeklySteps = ({ className }) => {
  const { weeklySteps } = useWeeklySteps();

  const weeklyStepsPercentage = useMemo(() => {
    if (!weeklySteps) {
      return 0;
    }

    return Math.min(
      Math.round((weeklySteps.currentWeek.steps / GOAL) * 100),
      100
    );
  }, [weeklySteps]);

  return (
    <DashboardTile title="Weekly steps" className={`flex-[1] ${className}`}>
      <div className="flex h-full w-full justify-center items-center">
        <Circular percentage={weeklyStepsPercentage} />
      </div>
    </DashboardTile>
  );
};

export default WeeklySteps;
