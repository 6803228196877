import axios from "axios";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  withCredentials: true,
});

const token = localStorage.getItem("token");
if (token) {
  axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}

axiosInstance.interceptors.response.use(
  function (response) {
    // If the response was successful, there's no need to do anything, just return the response
    return response;
  },
  function (error) {
    // If the response had a status code of 401 (Unauthorized), throw an error
    if (error.response.status === 401) {
      throw error;
    }

    // Otherwise, the error was caused by something else (like a network error), so reject the promise with the error
    return Promise.reject(error);
  }
);

export default axiosInstance;
