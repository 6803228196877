import L from "leaflet";

export function drawRoute(map, start, end, color) {
  console.debug("Drawing route with color %s from %o to %o", color, start, end);

  const routingControl = L.Routing.control({
    waypoints: [L.latLng(start[0], start[1]), L.latLng(end[0], end[1])],
    router: L.Routing.mapbox(process.env.REACT_APP_MAPBOX_API_KEY, {
      // profile: "mapbox/cycling",
      serviceUrl: `${process.env.REACT_APP_BACKEND_URL}/api/mapbox/directions/v5`,
    }),
    routeWhileDragging: false,
    drag: false,
    show: false,
    showInstructions: false,
    showAlternatives: false,
    draggable: false,
    addWaypoints: false,
    createMarker: function () {
      return null;
    },
    lineOptions: {
      styles: [
        {
          color,
          lineCap: "round",
          weight: 3,
        },
      ],
    },
  }).addTo(map);

  routingControl.on("routesfound", function (e) {
    map.fitBounds([L.latLng(start[0], start[1]), L.latLng(end[0], end[1])]);
  });

  routingControl.on("routingerror", function (e) {
    console.warn("No route found", e);
  });

  return routingControl;
}
