import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { useNotificationContext } from "../../../context/notification.context";
import axiosInstance from "../../../util/Axios.util";
import Logo from "../../shared/logo/Logo";

const RegistrationForm = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { notify } = useNotificationContext();
  const { register, handleSubmit } = useForm({
    defaultValues: {
      displayName: "",
      email: null,
      password: null,
    },
  });

  const toLogin = useCallback(() => {
    searchParams.set("context", "login");
    setSearchParams(searchParams);
  }, [searchParams, setSearchParams]);

  const submit = useCallback(
    async ({ displayName, email, password }) => {
      try {
        await axiosInstance.post(`/auth/register`, {
          displayName,
          email,
          password,
        });
        notify("success", {
          title: "Registration successful",
          description:
            "Ready to sign in! Also make sure to check your mails to verify your email address!",
        });
        toLogin();
      } catch (err) {
        console.error(err);
        notify("error", {
          title: "Registration failed",
          description:
            err?.response?.data?.error ||
            "An unknown error occurred. Please check the form and try again.",
        });
      }
    },
    [toLogin, notify]
  );

  return (
    <form onSubmit={handleSubmit(submit)} className="flex flex-col gap-4">
      <Logo className="w-32 h-32 mx-auto" />
      <label>
        <div className="ml-2 text-sm">E-Mail address</div>
        <input
          className="oc-input"
          name="email"
          type="email"
          {...register(`email`, { required: true })}
        />
      </label>
      <label>
        <div className="ml-2 text-sm">Display name</div>
        <input
          className="oc-input"
          name="displayName"
          autoComplete="off"
          {...register(`displayName`, { required: true })}
        />
      </label>
      <label>
        <div className="ml-2 text-sm">Password</div>
        <input
          className="oc-input"
          name="password"
          autoComplete="off"
          type="password"
          {...register(`password`, { required: true })}
        />
      </label>
      <button className="bg-lime-300 hover:bg-lime-400 text-black p-2 rounded-md font-bold">
        Register
      </button>
      <button
        type="button"
        className="block p-2 w-full text-center"
        onClick={toLogin}
      >
        Sign in
      </button>
    </form>
  );
};

export default RegistrationForm;
