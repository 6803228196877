import { useCallback, useEffect, useReducer } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import axiosInstance from "../util/Axios.util";
import UserContext from "./user.context";

function userReducer(state, action) {
  switch (action.type) {
    case "UPDATE_USER":
      return { ...state, ...action.payload };
    case "RESET_USER":
      return null;
    default:
      throw new Error(`Unsupported action type: ${action.type}`);
  }
}

const UserProvider = ({ children }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [user, dispatch] = useReducer(userReducer, null);
  const navigate = useNavigate();
  const location = useLocation();

  const updateUser = useCallback(
    (user) => {
      dispatch({ type: "UPDATE_USER", payload: user });
    },
    [dispatch]
  );

  const resetUser = useCallback(() => {
    dispatch({ type: "RESET_USER" });
  }, [dispatch]);

  useEffect(() => {
    const token = searchParams.get("token");
    if (token) {
      // Store the token in your preferred state management solution
      // e.g., React Context, Redux, or even localStorage
      // This example uses localStorage
      localStorage.setItem("token", token);
      searchParams.delete("token");
      setSearchParams(searchParams);
      axiosInstance.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${token}`;
    }
  }, [searchParams, setSearchParams]);

  useEffect(() => {
    const logout = searchParams.get("logout");
    if (logout) {
      resetUser();
      searchParams.delete("logout");
      setSearchParams(searchParams);
    }
  }, [resetUser, searchParams, setSearchParams, updateUser]);

  useEffect(() => {
    if (
      ["/auth", "/signin", "/register", "/verify", "/reset-password"].includes(
        location.pathname
      ) ||
      user
    ) {
      return;
    }

    // Attempt to fetch the user
    axiosInstance
      .get("/api/user")
      .then((response) => {
        updateUser(response.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          navigate("/auth");
        }
      });
  }, [navigate, location.pathname, user, updateUser]);

  return (
    <UserContext.Provider value={{ user, updateUser, resetUser }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
