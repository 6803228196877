import ProfileContents from "../components/profile/profile-contents/ProfileContents";
import ProfileHeader from "../components/profile/profile-header/ProfileHeader";

const Profile = () => {
  return (
    <div className="oc-content px-0 py-0 flex flex-col overflow-hidden">
      <ProfileHeader className={""} />
      <ProfileContents className={"flex-1 overflow-y-auto"} />
    </div>
  );
};

export default Profile;
