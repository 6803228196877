const DashboardTile = ({
  title = "",
  children,
  className = "",
  noPadding = false,
}) => {
  return (
    <div className={`h-full w-full relative flex flex-col ${className}`}>
      {title && (
        <h2 className="text-neutral-400 ml-1 md:ml-4 mb-1 md:mb-2 text-xs md:text-xl font-medium">
          {title}&nbsp;
        </h2>
      )}
      <div
        className={`flex-1 bg-neutral-950 ${
          !noPadding ? "p-3 md:p-4" : "p-0"
        } overflow-hidden`}
      >
        {children}
      </div>
    </div>
  );
};

export default DashboardTile;
