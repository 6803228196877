import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";

export function useAutocompleteOptions(
  loadOptionsFunc,
  inputValue,
  selectedOption,
  minCharCount = 0
) {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadOptions = useCallback(
    debounce((input) => {
      if (input.length >= minCharCount && selectedOption?.label !== input) {
        setLoading(true);
        loadOptionsFunc(input).then((options) => {
          setOptions(options);
          setLoading(false);
        });
      } else {
        setOptions([]);
      }
    }, 500),
    [loadOptionsFunc, minCharCount]
  );

  useEffect(() => {
    loadOptions(inputValue);
  }, [loadOptions, inputValue]);

  const resetOptions = useCallback(() => setOptions([]), []);

  return {
    options,
    loading,
    resetOptions,
  };
}
