import { useCallback, useContext, useMemo } from "react";
import { useNotificationContext } from "../../../context/notification.context";
import UserContext from "../../../context/user.context";
import { useUpdateMe } from "../../../hooks/use-update-me.hook";
import { unknownError } from "../../../util/Notification.util";
import CharacterSelectionTile from "./character-selection-tile/CharacterSelectionTile";

const CharacterSelection = ({ onCharacterSelected }) => {
  const { notify } = useNotificationContext();
  const { user } = useContext(UserContext);
  const updateMe = useUpdateMe();

  const updateCharacter = useCallback(
    async (character) => {
      updateMe({ character })
        .then(() => onCharacterSelected && onCharacterSelected(character))
        .catch((err) => {
          console.error(err);
          notify(...unknownError);
        });
    },
    [updateMe, onCharacterSelected, notify]
  );

  const currentCharacter = useMemo(() => user?.character, [user]);

  return (
    <div className="w-full oc-card absolute inset-0 opacity-90">
      <div className="w-full h-full flex gap-4 justify-center items-center">
        <CharacterSelectionTile
          character="Cat"
          animation="Idle"
          selected={currentCharacter === "Cat"}
          onClick={updateCharacter}
        />
        <CharacterSelectionTile
          character="Boxer"
          animation="Idle"
          selected={currentCharacter === "Boxer"}
          onClick={updateCharacter}
        />
        <CharacterSelectionTile
          character="Fox"
          animation="Idle"
          selected={currentCharacter === "Fox"}
          onClick={updateCharacter}
        />
        <CharacterSelectionTile
          character="Mage"
          animation="Idle"
          selected={currentCharacter === "Mage"}
          onClick={updateCharacter}
        />
        <CharacterSelectionTile
          character="Ninja"
          animation="Idle"
          selected={currentCharacter === "Ninja"}
          onClick={updateCharacter}
        />
      </div>
    </div>
  );
};

export default CharacterSelection;
