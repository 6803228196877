import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import ForgotPasswordForm from "../components/authentication/forgot-password-form/ForgotPasswordForm";
import LoginForm from "../components/authentication/login-form/LoginForm";
import RegistrationForm from "../components/authentication/registration-form/RegistrationForm";

const AuthenticationPage = () => {
  const [searchParams] = useSearchParams();

  const Form = useMemo(() => {
    switch (searchParams.get("context")) {
      case "register":
        return RegistrationForm;
      case "forgot-password":
        return ForgotPasswordForm;
      default:
        return LoginForm;
    }
  }, [searchParams]);

  return (
    <>
      <div className="opacity-25 fixed inset-0 overflow-hidden z-0">
        <img
          src="/img/running-forest-day.gif"
          className="w-full h-full object-cover md:object-contain rendering-pixelated animate-fadeIn"
          alt=""
        />
      </div>

      <div className="oc-content relative z-10 items-center justify-center overflow-hidden">
        <div className="oc-card w-full max-w-sm z-10">
          <Form />
        </div>
      </div>
    </>
  );
};

export default AuthenticationPage;
