import DailyStepsSettings from "../daily-steps-settings/DailyStepsSettings";

const PersonalSettings = () => {
  return (
    <div className="w-full h-full md:py-4">
      <div className="max-w-[520px] mx-auto">
        <h1 className="w-full text-3xl mb-4">Personal Settings</h1>
        <div className="p-4 bg-neutral-900 md:bg-neutral-950">
          <DailyStepsSettings />
        </div>
      </div>
    </div>
  );
};

export default PersonalSettings;
