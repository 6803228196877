// NotificationContext.js
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

export const NotificationContext = createContext();

export const useNotificationContext = () => {
  return useContext(NotificationContext);
};

export const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);

  const notify = useCallback((type, config) => {
    setNotifications((current) => [
      ...current,
      { id: Date.now(), type, ...config },
    ]);
  }, []);

  const dismiss = useCallback((id) => {
    setNotifications((current) => current.filter((n) => n.id !== id));
  }, []);

  const value = useMemo(
    () => ({ notify, dismiss, notifications }),
    [notify, dismiss, notifications]
  );

  return (
    <NotificationContext.Provider value={value}>
      {children}
    </NotificationContext.Provider>
  );
};
