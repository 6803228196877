import React from "react";
import { RiMapPinAddFill } from "react-icons/ri";
import { NavLink } from "react-router-dom";
import ChallengeListHighlight from "../components/challenge/challenge-list-highlight/ChallengeListHighlight";
import ChallengeList from "../components/challenge/challenge-list/ChallengeList";
import ChallengeMissing from "../components/challenge/challenge-missing/ChallengeMissing";
import Loading from "../components/shared/loading/Loading";
import { useChallenges } from "../hooks/use-challenges.hook";

const Challenges = () => {
  const { challenges, loading } = useChallenges();

  return (
    <div className="relative flex flex-col h-full w-full text-neutral-100 p-3 md:py-4 md:px-8 max-w-2xl mx-auto">
      {loading && <Loading />}
      {!loading && challenges && !(challenges.length > 0) && (
        <ChallengeMissing />
      )}
      {!loading && challenges?.length > 0 && (
        <>
          <h2 className="text-center md:text-left text-neutral-400 ml-1 md:ml-4 mb-2 text-md md:text-xl font-medium">
            Your challenges
          </h2>
          <ChallengeListHighlight challenge={challenges[0]} />
          <ChallengeList challenges={challenges.slice(1)} />
          <NavLink
            className="absolute bottom-4 right-4 rounded-full"
            to="/challenges/new"
          >
            <button className="rounded-full p-3 bg-purple-800 flex justify-center items-center gap-2">
              <RiMapPinAddFill className="w-8 h-8" />
              <div className="hidden md:block">New challenge</div>
            </button>
          </NavLink>
        </>
      )}
    </div>
  );
};

export default Challenges;
