import { useEffect, useState } from "react";
import { useNotificationContext } from "../context/notification.context";
import axiosInstance from "../util/Axios.util";
import { unknownError } from "../util/Notification.util";

export function useChallenges() {
  const { notify } = useNotificationContext();
  const [challenges, setChallenges] = useState();
  const [loading, setLoading] = useState();
  const [error, setError] = useState();

  useEffect(() => {
    const fetchChallenge = async () => {
      try {
        setLoading(true);
        const response = await axiosInstance.get(`/api/challenges`);
        setChallenges(response.data);
      } catch (error) {
        // TODO: More specific error?
        notify(...unknownError);
        console.warn("Error fetching challenges:", error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchChallenge();
  }, [notify]);

  return {
    challenges,
    loading,
    error,
  };
}
