export function extractMapboxLocationData(feature) {
  // Start with an empty location object
  const location = {
    address: null,
    place: null,
    country: null,
    postcode: null,
  };

  // Function to extract the type and text
  const extractTypeAndText = (data) => {
    const type = data.id.split(".")[0];
    if (["address", "place", "country", "postcode"].includes(type)) {
      location[type] = data.text;
    }
  };

  // Check if the main feature is the address, place, country or postcode
  extractTypeAndText(feature);

  // Extract place, country, postcode, and address from context if they are available
  if (Array.isArray(feature.context)) {
    feature.context.forEach(extractTypeAndText);
  }

  return location;
}

export function locationsToName(startLocation, endLocation) {
  function routeName(start, end) {
    return `${start} to ${end}!`;
  }

  if (!startLocation || !endLocation) {
    return "";
  }

  if (startLocation.place && endLocation.place) {
    return routeName(startLocation.place, endLocation.place);
  }

  if (startLocation.country && endLocation.country) {
    return routeName(startLocation.country, endLocation.country);
  }

  return "";
}
