import { useMemo } from "react";
import DashboardTile from "../../dashboard-tile/DashboardTile";
import Circular from "../circular/Circular";

const ChallengeProgress = ({ challenge, className }) => {
  const percentage = useMemo(() => {
    if (!challenge) {
      return 0;
    }

    return Math.round((challenge.progress / challenge.distance) * 100);
  }, [challenge]);

  return (
    <DashboardTile
      title="Challenge progress"
      className={`flex-[1] ${className}`}
    >
      <div className="flex h-full w-full justify-center items-center">
        <Circular percentage={percentage} />
      </div>
    </DashboardTile>
  );
};

export default ChallengeProgress;
