import { useEffect } from "react";
import { useMap } from "react-leaflet";

const FitBounds = ({ coordinates }) => {
  const map = useMap();

  useEffect(() => {
    if (!map || !coordinates) {
      return;
    }

    map.fitBounds(coordinates);
  }, [coordinates, map]);

  return null;
};

export default FitBounds;
