import { useEffect, useMemo, useState } from "react";
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import { useNotificationContext } from "../context/notification.context";
import axiosInstance from "../util/Axios.util";
import { unknownError } from "../util/Notification.util";

const VerifyEmailPage = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { notify } = useNotificationContext();
  const verificationToken = useMemo(
    () => searchParams.get("t"),
    [searchParams]
  );

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        await axiosInstance.post("/auth/verify", { verificationToken });
        notify("success", {
          title: "Email verified successfully",
          description: "Your email has been verified. Start off your journey!",
        });
        setLoading(false);
        navigate("/");
      } catch (err) {
        notify(...unknownError);
        setError(true);
        setLoading(false);
      }
    };

    if (verificationToken) {
      verifyEmail();
    } else {
      setError(true);
      setLoading(false);
    }
  }, [verificationToken, notify, navigate]);

  return (
    <div className="oc-content items-center justify-center">
      <div className="oc-card w-full max-w-sm">
        {loading && <div>Verifying...</div>}
        {error && (
          <div>
            <p className="mb-4">
              Email could not be verified. Please try again.
            </p>
            <NavLink to="/">Back to Home</NavLink>
          </div>
        )}
        {!loading && !error && (
          <div>
            <p>Email verified successfully!</p>
            <NavLink to="/">Go to Home</NavLink>
          </div>
        )}
      </div>
    </div>
  );
};

export default VerifyEmailPage;
