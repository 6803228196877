import { useCallback, useContext } from "react";
import { RiLinkUnlinkM } from "react-icons/ri";
import { useNotificationContext } from "../../../context/notification.context";
import UserContext from "../../../context/user.context";
import { useUnlinkProvider } from "../../../hooks/use-unlink-provider.hook";
import { unknownError } from "../../../util/Notification.util";
import { ConfirmDialog } from "../../shared/confirm-dialog/ConfirmDialog";
import { useConfirmDialog } from "../../shared/confirm-dialog/use-confirm-dialog.hook";

export const UnlinkFitnessProviderButton = () => {
  const { user } = useContext(UserContext);
  const { notify } = useNotificationContext();
  const unlinkProvider = useUnlinkProvider();
  const {
    isOpen,
    open: openConfirmDialog,
    close: closeConfirmDialog,
  } = useConfirmDialog();

  const unlink = useCallback(() => {
    closeConfirmDialog();
    try {
      unlinkProvider();
    } catch (err) {
      notify(...unknownError);
    }
  }, [closeConfirmDialog, unlinkProvider, notify]);

  return (
    !!user.provider && (
      <>
        <button
          onClick={openConfirmDialog}
          className="flex items-center justify-center w-full h-6 gap-2 text-red-700"
        >
          <RiLinkUnlinkM className="w-4 h-4" />
          <div>Unlink provider</div>
        </button>

        <ConfirmDialog
          open={isOpen}
          onCancel={closeConfirmDialog}
          onConfirm={unlink}
        >
          Are you sure you want to unlink your fitness provider?
        </ConfirmDialog>
      </>
    )
  );
};
