import { RiMapPinAddFill } from "react-icons/ri";
import { NavLink } from "react-router-dom";

const ChallengeMissing = ({ className }) => {
  return (
    <div
      className={`w-full h-full flex flex-col items-center justify-center ${className}`}
    >
      <h2 className="text-2xl font-bold mb-2">No active challenge</h2>
      <div>Start a new challenge right now!</div>
      <NavLink to="/challenges/new">
        <button className="rounded-full p-3 bg-purple-800 flex justify-center items-center gap-2 mt-6">
          <RiMapPinAddFill className="w-8 h-8" />
          <div className="block font-bold">New challenge</div>
        </button>
      </NavLink>
    </div>
  );
};

export default ChallengeMissing;
