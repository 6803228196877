import { useCallback, useContext } from "react";
import UserContext from "../context/user.context";
import axiosInstance from "../util/Axios.util";

export function useUpdateMe() {
  const { updateUser } = useContext(UserContext);

  return useCallback(
    async ({ dailyStepGoal, character, challengeNotificationInterval }) => {
      return axiosInstance
        .patch(`/api/user/me`, {
          dailyStepGoal,
          character,
          challengeNotificationInterval,
        })
        .then((response) => {
          updateUser(response.data.user);
        });
    },
    [updateUser]
  );
}
