import Bar from "./Bar";

const Bars = ({ className = "", data = [] }) => {
  return (
    <div
      className={`w-full flex gap-1 md:gap-4 justify-center ${
        !className.includes("h-") && "h-full"
      } ${className}`}
    >
      {data.map(({ percentage, label }, i) => (
        <Bar key={i} index={i} percentage={percentage} label={label} />
      ))}
    </div>
  );
};

export default Bars;
