import React, { useCallback, useState } from "react";
import { useAutocompleteOptions } from "./use-autocomplete-options.hooks";
import { useKeyControlsForAutocomplete } from "./use-key-controls-for-autocomplete.hook";

const Autocomplete = React.forwardRef(
  (
    {
      loadOptions: loadOptionsFunc,
      minCharCount,
      onChange,
      className = "",
      inputProps,
    },
    ref
  ) => {
    const [inputValue, setInputValue] = useState("");
    const [selectedOption, setSelectedOption] = useState(null);
    const [isFocused, setFocused] = useState(false);

    const { options, loading, resetOptions } = useAutocompleteOptions(
      loadOptionsFunc,
      inputValue,
      selectedOption,
      minCharCount
    );

    const handleInputChange = useCallback(
      (e) => {
        resetOptions();
        setInputValue(e.target.value);
      },
      [resetOptions]
    );

    const handleFocus = useCallback((e) => {
      setFocused(true);
      window.setTimeout(e.target.select(), 200);
    }, []);

    const handleBlur = useCallback(
      (e) => {
        if (!selectedOption) {
          setInputValue("");
        } else {
          setInputValue(selectedOption.label);
        }

        setFocused(false);
      },
      [selectedOption]
    );

    const handleOptionSelect = useCallback(
      (option) => {
        setSelectedOption(option);
        onChange(option);
        setInputValue(option.label);
        resetOptions();
      },
      [onChange, resetOptions]
    );

    const { highlightedOption, handleKeyDown } = useKeyControlsForAutocomplete(
      options,
      handleOptionSelect
    );

    return (
      <div className={`relative w-full ${className}`}>
        <input
          className="w-full h-full bg-transparent border-2 border-neutral-700 py-3 px-2"
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          ref={ref}
          {...inputProps}
        />
        {isFocused && ((options && options.length > 0) || loading) && (
          <div className="absolute z-[500] left-0 right-0">
            {loading && <div className="p-2 bg-neutral-950">Loading...</div>}
            {options?.map((option) => (
              <div
                className={`p-2 hover:bg-neutral-700 ${
                  option.label === highlightedOption?.label
                    ? "bg-neutral-700"
                    : "bg-neutral-950"
                }`}
                key={option.label}
                onMouseDown={() => handleOptionSelect(option)}
              >
                {option.label}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }
);

export default Autocomplete;
