import React from "react";

class ErrorBoundary extends React.Component {
  state = { hasError: false, error: null };

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error: error };
  }

  componentDidUpdate(prevProps, prevState) {
    // If the error is a 401, redirect to the sign-in page
    if (this.state.hasError && this.state.error.response.status === 401) {
      this.props.history.push("/auth");
    }
  }

  render() {
    if (this.state.hasError) {
      // You can also render a fallback UI here
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
