const Imprint = () => {
  return (
    <div className="oc-container">
      <div className="oc-content prose prose-sm sm:prose lg:prose-lg xl:prose-2xl mx-auto">
        <h2 className="text-2xl font-bold">Imprint</h2>
        <p className="text-md mb-2">
          PixelPace ("Application") is provided by Benjamin Adam ("Provider").
        </p>

        <h3 className="text-xl font-bold mt-4">Contact Information</h3>
        <p className="text-md mb-2">You can contact the Provider at:</p>
        <p className="text-md mb-2">
          Eickener Str., 41063 Mönchengladbach, Germany
        </p>
        <p className="text-md mb-2">Email: m.benjamin.adam@gmail.com</p>

        <h3 className="text-xl font-bold mt-4">Legal Disclosure</h3>
        <p className="text-md mb-2">
          Information in accordance with Section 5 TMG (in countries where this
          is applicable).
        </p>

        <h3 className="text-xl font-bold mt-4">
          Person responsible for content
        </h3>
        <p className="text-md mb-2">
          In accordance with Section 55, Para. 2 of the German Interstate
          Broadcasting Agreement (RStV) (if applicable): Benjamin Adam
        </p>
      </div>
    </div>
  );
};

export default Imprint;
