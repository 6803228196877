import GoogleFitIcon from "../google-fit-icon/GoogleFitIcon";

const GoogleFitButton = ({ className = "", onClick }) => {
  return (
    <button
      onClick={onClick}
      className={`min-w-[240px] h-[50px] bg-[#4285F3] flex items-center rounded-[2px] ${className}`}
    >
      <GoogleFitIcon />
      <div className="flex-1 text-center font-bold">
        Connect with Google Fit
      </div>
    </button>
  );
};

export default GoogleFitButton;
