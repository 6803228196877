import { RiLoader3Fill } from "react-icons/ri";

const RouteFormStats = ({ routeStats, loading }) => {
  return (
    (routeStats || loading) && (
      <div className="hidden md:block">
        <div className="text-sm ml-2">Stats</div>
        <div className="grid grid-cols-[max-content_1fr] gap-x-4 gap-y-2 py-3 px-4 border border-neutral-800 rounded-md">
          <div>Distance</div>
          <div className="flex justify-end items-center">
            {loading ? (
              <RiLoader3Fill className="animate-spin text-neutral-500" />
            ) : (
              <>~{Math.round(routeStats.distanceInMeters / 1000)} km</>
            )}
          </div>
          <div>With 7000 steps per day</div>
          <div className="flex justify-end items-center">
            {loading ? (
              <RiLoader3Fill className="animate-spin text-neutral-500" />
            ) : (
              <>~{Math.round(routeStats.distanceInMeters / 1000 / 5)} days</>
            )}
          </div>
          <div>With 10000 steps per day</div>
          <div className="flex justify-end items-center">
            {loading ? (
              <RiLoader3Fill className="animate-spin text-neutral-500" />
            ) : (
              <>~{Math.round(routeStats.distanceInMeters / 1000 / 8)} days</>
            )}
          </div>
        </div>
      </div>
    )
  );
};

export default RouteFormStats;
