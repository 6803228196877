import ChallengeListItem from "../challenge-list-item/ChallengeListItem";

const ChallengeList = ({ challenges, className }) => {
  return (
    challenges &&
    challenges.length > 0 && (
      <ul className={className}>
        {challenges.map((challenge) => (
          <ChallengeListItem key={challenge.id} challenge={challenge} />
        ))}
      </ul>
    )
  );
};

export default ChallengeList;
