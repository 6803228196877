export const ConfirmDialog = ({ children, open, onConfirm, onCancel }) => {
  return (
    open && (
      <div className="fixed inset-0 flex items-center justify-center bg-black/50 backdrop-blur-sm z-[10000]">
        <div className="max-w-xs md:max-w-md w-full p-4 bg-neutral-900 rounded-md flex flex-col gap-4">
          <div className="flex-1">{children}</div>
          <div className="flex-initial flex gap-4">
            <button
              onClick={onConfirm}
              className="flex-1 bg-lime-300 hover:bg-lime-400 text-black p-2 rounded-md font-bold"
            >
              Confirm
            </button>
            <button
              onClick={onCancel}
              className="flex-1 bg-neutral-300 hover:bg-neutral-400 text-black p-2 rounded-md font-bold"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    )
  );
};
