import { useCallback, useState } from "react";

export function useConfirmDialog() {
  const [isOpen, setOpen] = useState(false);

  const open = useCallback(() => setOpen(true), []);
  const close = useCallback(() => setOpen(false), []);

  return { isOpen, open, close };
}
