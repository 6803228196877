import { useEffect, useState } from "react";
import { useNotificationContext } from "../context/notification.context";
import axiosInstance from "../util/Axios.util";
import { unknownError } from "../util/Notification.util";

export function useWeeklySteps() {
  const { notify } = useNotificationContext();
  const [weeklySteps, setWeeklySteps] = useState();
  const [loading, setLoading] = useState();
  const [error, setError] = useState();

  useEffect(() => {
    const fetchWeeklySteps = async () => {
      try {
        setLoading(true);
        const response = await axiosInstance.get(`/api/fit/weekly-steps`);
        setWeeklySteps(response.data);
      } catch (error) {
        console.warn("Error fetching weekly steps:", error);
        // TODO: Leave this to the component?
        notify(...unknownError);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchWeeklySteps();
  }, [notify]);

  return {
    weeklySteps,
    loading,
    error,
  };
}
