import moment from "moment";

export function printChallengeProgress({ progress, distance }) {
  const percentageCovered = Math.min((progress / distance) * 100, 100);

  return `${(progress / 1000).toFixed(2)} of ${(distance / 1000).toFixed(
    2
  )} covered (${percentageCovered.toFixed(2)}%)`;
}

export function printChallengeStart({ createdAt }) {
  const timestamp = moment(createdAt).format("DD.MM.YYYY HH:mm");

  return `Running since ${timestamp}`;
}

export function printChallengeCompletionDate({ completedAt }) {
  const timestamp = moment(completedAt).format("DD.MM.YYYY HH:mm");

  return `${timestamp}`;
}

export function printChallengeCompleted({ completedAt }) {
  const timestamp = moment(completedAt).format("DD.MM.YYYY HH:mm");

  return `Completed ${timestamp}`;
}
