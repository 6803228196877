import { useCallback, useContext, useMemo } from "react";
import {
  RiArrowDropRightLine,
  RiHeartPulseFill,
  RiNotification2Fill,
  RiUserSettingsFill,
} from "react-icons/ri";
import { NavLink, Outlet, useLocation } from "react-router-dom";
import UserContext from "../context/user.context";
import { useLogout } from "../hooks/use-logout.hook";

const Settings = () => {
  const { user } = useContext(UserContext);
  const logout = useLogout();
  const location = useLocation();

  const isIndexRoute = useMemo(
    () => location.pathname === "/settings",
    [location.pathname]
  );

  const classNameFunc = useCallback(
    ({ isActive }) =>
      `"w-full h-12 md:h-16 text-lg md:text-xl flex items-center gap-4 border-b border-neutral-700 px-4 last-of-type:border-0 ${
        isActive ? "bg-neutral-900" : ""
      }`,
    []
  );

  return (
    user && (
      <div className="relative overflow-hidden flex w-full h-full text-white">
        <div
          className={`flex-1 md:min-w-[360px] w-full py-4 md:py-8 transition-transform ${
            isIndexRoute
              ? "translate-x-0"
              : "-translate-x-full md:translate-x-0"
          } absolute inset-0 md:relative md:block`}
        >
          <h1 className="w-full text-3xl mb-4 px-4">Settings</h1>
          <NavLink className={classNameFunc} to={"data-source"}>
            <RiHeartPulseFill />
            <div className="flex-1">Health data source</div>
            <RiArrowDropRightLine className="w-8 h-8" />
          </NavLink>
          <NavLink className={classNameFunc} to={"personal"}>
            <RiUserSettingsFill />
            <div className="flex-1">Personal settings</div>
            <RiArrowDropRightLine className="w-8 h-8" />
          </NavLink>
          <NavLink className={classNameFunc} to={"notifications"}>
            <RiNotification2Fill />
            <div className="flex-1">Notification settings</div>
            <RiArrowDropRightLine className="w-8 h-8" />
          </NavLink>
          <div className="p-4 md:p-8">
            <button
              className="w-full bg-neutral-900 p-4 rounded-md font-bold mt-4"
              onClick={logout}
            >
              Sign out
            </button>
          </div>
        </div>
        <div
          className={`flex-[3] flex p-4 transition-transform md:bg-neutral-900 ${
            isIndexRoute ? "translate-x-full md:translate-x-0" : "translate-x-0"
          }  absolute inset-0 md:relative flex flex-col`}
        >
          <Outlet className="flex-1" />
          {/*
          <NavLink to={""} className="block md:hidden">
            <button className="flex items-center w-full text-center">
              <RiArrowDropLeftLine className="w-8 h-8" />
              <div className="flex-1">Back to settings</div>
            </button>
          </NavLink>
          */}
        </div>
      </div>
    )
  );
};

export default Settings;
