import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import ChallengeMap from "../components/challenge/challenge-map/ChallengeMap";
import PageHeader from "../components/shared/page-header/PageHeader";
import { useNotificationContext } from "../context/notification.context";
import axiosInstance from "../util/Axios.util";
import { printChallengeCompletionDate } from "../util/Challenge.util";
import { unknownError } from "../util/Notification.util";

const Challenge = ({ challengeId: externalChallengeId }) => {
  const [challenge, setChallenge] = useState();
  const { challengeId } = useParams();
  const { notify } = useNotificationContext();

  // Move to hook
  useEffect(() => {
    const fetchChallenge = async () => {
      try {
        const response = await axiosInstance.get(
          `/api/challenges/${challengeId || externalChallengeId}`
        );
        setChallenge(response.data);
      } catch (error) {
        console.warn("Error fetching challenge:", error);
        // TODO: Leave this to the component?
        notify(...unknownError);
      }
    };

    fetchChallenge();
  }, [challengeId, externalChallengeId, notify]);

  const estimatedEnd = useMemo(() => {
    if (!challenge) {
      return null;
    }

    const startDate = moment(challenge.createdAt);
    const currentDate = moment();
    const daysSinceStart = currentDate.diff(startDate, "days");
    const averageProgressPerDay = challenge.progress / daysSinceStart;
    const remainingDistance = challenge.distance - challenge.progress;
    const estimatedDaysToCompletion = remainingDistance / averageProgressPerDay;
    return moment().add(estimatedDaysToCompletion, "days");
  }, [challenge]);

  return (
    challenge && (
      <div className="flex flex-col-reverse md:flex-row h-full w-full bg-black text-neutral-100">
        <div className="flex-1 flex flex-col md:flex-row">
          <div className="flex-grow-0 flex-shrink-0 p-4 md:px-8 md:py-6 md:w-[480px] md:max-w-[50%]">
            <PageHeader to="/challenges">
              {challenge ? challenge.name : ""}
            </PageHeader>

            <table className="table-fixed w-full mb-2 md:mb-4">
              <tbody>
                <tr>
                  <td className="pr-2">
                    <div className="text-xs text-neutral-400">Start</div>
                    <div className="text-sm whitespace-pre mb-4 overflow-hidden text-ellipsis">
                      {challenge && challenge.start.replace(/, ?/g, "\n")}
                    </div>
                  </td>
                  <td className="pl-2">
                    <div className="text-xs text-neutral-400">Goal</div>
                    <div className="text-sm whitespace-pre mb-4 overflow-hidden text-ellipsis">
                      {challenge && challenge.end.replace(/, ?/g, "\n")}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>

            {challenge && (
              <table className="w-full text-sm">
                <tbody>
                  <tr>
                    <th align="left">Total distance</th>
                    <td align="right">
                      {(challenge.distance / 1000).toFixed(2)} km
                    </td>
                  </tr>
                  <tr>
                    <th align="left">Covered</th>
                    <td align="right">
                      {(challenge.progress / 1000).toFixed(2)} km
                    </td>
                  </tr>
                  <tr>
                    <th align="left">Progress today</th>
                    <td align="right">
                      {(
                        (challenge.progress - challenge.progressYesterday) /
                        1000
                      ).toFixed(2)}{" "}
                      km
                    </td>
                  </tr>
                  <tr>
                    <th align="left">
                      {challenge.isCompleted
                        ? "Started running at"
                        : "Running since"}
                    </th>
                    <td align="right">
                      {moment(challenge.createdAt).format("DD.MM.YYYY HH:mm")}
                    </td>
                  </tr>

                  {!challenge.isCompleted && (
                    <tr>
                      <th align="left">Estimated arrival</th>
                      <td align="right">
                        {moment(estimatedEnd).format("DD.MM.YYYY")}
                      </td>
                    </tr>
                  )}

                  {challenge.isCompleted && (
                    <tr>
                      <th align="left">Arrived at</th>
                      <td align="right">
                        {printChallengeCompletionDate(challenge)}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            )}
          </div>
          <ChallengeMap className="flex-1" challenge={challenge} fitBounds />
        </div>
      </div>
    )
  );
};

export default Challenge;
