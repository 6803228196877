import { RiLoader3Fill } from "react-icons/ri";

const Loading = ({ className }) => {
  return (
    <div
      className={`w-full h-full flex items-center justify-center animate-fadeIn ${className}`}
    >
      <RiLoader3Fill className="animate-spin w-12 h-12" />
    </div>
  );
};

export default Loading;
