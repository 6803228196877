const Logo = ({ className }) => {
  return (
    <div className={className}>
      <img
        className="w-full h-full mx-auto rounded-full"
        src="icons/icon-128x128.png"
        alt="Logo"
      />
    </div>
  );
};

export default Logo;
