import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import Map from "../components/challenge/map/Map";
import RouteForm from "../components/challenge/route-form/RouteForm";
import PageHeader from "../components/shared/page-header/PageHeader";

const ChallengeNew = () => {
  const [map, setMap] = useState();
  const navigate = useNavigate();

  const onChallengeCreated = useCallback(() => {
    navigate("/challenges");
  }, [navigate]);

  return (
    <div className="flex flex-col-reverse md:flex-row h-full w-full text-neutral-100">
      <div className="flex-1 flex flex-col md:flex-row">
        <div className="flex-grow-0 flex-shrink-0 p-2 md:p-4 flex flex-col">
          <PageHeader to="/challenges">Create your challenge!</PageHeader>
          <RouteForm
            className="flex-1"
            map={map}
            onChallengeCreated={onChallengeCreated}
          />
        </div>
        <Map className="flex-1" onMapChange={setMap} />
      </div>
    </div>
  );
};

export default ChallengeNew;
