import { useCallback, useEffect, useState } from "react";

export function useKeyControlsForAutocomplete(options, onOptionSelect) {
  const [highlightedOption, setHighlightedOption] = useState();

  useEffect(() => {
    if (options && options.length > 0) {
      setHighlightedOption(options[0]);
      return;
    }

    setHighlightedOption(null);
  }, [options]);

  const handleKeyDown = useCallback(
    (e) => {
      switch (e.key) {
        case "ArrowDown": {
          e.stopPropagation();
          e.preventDefault();
          const nextIndex = options.indexOf(highlightedOption) + 1;
          if (nextIndex < options.length) {
            setHighlightedOption(options[nextIndex]);
          }
          break;
        }
        case "ArrowUp": {
          e.stopPropagation();
          e.preventDefault();
          const prevIndex = options.indexOf(highlightedOption) - 1;
          if (prevIndex >= 0) {
            setHighlightedOption(options[prevIndex]);
          }
          break;
        }
        case "Enter": {
          if (highlightedOption) {
            e.stopPropagation();
            e.preventDefault();
            onOptionSelect(highlightedOption);
          }
          break;
        }
        default:
          break;
      }
    },
    [onOptionSelect, highlightedOption, options]
  );

  return {
    handleKeyDown,
    highlightedOption,
  };
}
