import "leaflet-routing-machine";
import "lrm-mapbox";
import { useCallback, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNotificationContext } from "../../../context/notification.context";
import { locationsToName } from "../../../util/Address.util";
import axiosInstance from "../../../util/Axios.util";
import { unknownError } from "../../../util/Notification.util";
import AddressSelect from "../../address-select/AddressSelect";
import RouteFormError from "./RouteFormError";
import RouteFormStats from "./RouteFormStats";
import { useGenerateRoute } from "./use-generate-route.hook";

const RouteForm = ({ className, map, onChallengeCreated }) => {
  const { notify } = useNotificationContext();
  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    formState: { touchedFields },
  } = useForm({
    defaultValues: {
      name: "",
      start: null,
      end: null,
      route: null,
    },
  });

  const currentValues = watch();
  const { route, routeError, routeLoading, routePoints, routeStats } =
    useGenerateRoute(currentValues.start, currentValues.end, map);

  const submit = useCallback(
    async ({ name, start, end, route }) => {
      try {
        const payload = {
          name,
          start: start.label,
          end: end.label,
          startContext: start.value,
          endContext: end.value,
          route: routePoints,
          distance: routeStats.distanceInMeters,
        };
        const response = await axiosInstance.post("/api/challenges", payload);
        onChallengeCreated(response.data);
      } catch (err) {
        notify(...unknownError);
        console.warn("Failed to create new challenge", err);
      }
    },
    [routeStats, routePoints, onChallengeCreated, notify]
  );

  useEffect(() => {
    setValue("route", route);
  }, [route, setValue]);

  useEffect(() => {
    if (routeError) {
      console.warn("route error!", routeError);
      notify("error", {
        title: "No route found",
        description:
          "Could not determine a route for your start & destination. Please try a different combination!",
      });
    }
  }, [routeError, notify]);

  useEffect(() => {
    if (
      !currentValues.start ||
      !currentValues.end ||
      !routeStats ||
      touchedFields.name
    ) {
      return;
    }

    setValue(
      "name",
      locationsToName(currentValues.start.value, currentValues.end.value)
    );
  }, [
    currentValues.end,
    currentValues.start,
    routeStats,
    setValue,
    touchedFields.name,
  ]);

  return (
    map && (
      <form
        onSubmit={handleSubmit(submit)}
        className={`${className} p-2 md:p-4 text-white`}
      >
        <div className="max-w-full flex flex-col gap-2 md:gap-4">
          <label>
            <div className="ml-2 text-sm">Start</div>
            <Controller
              name="start"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <AddressSelect
                  inputProps={{
                    placeholder: "Where do you start?",
                    disabled: routeLoading,
                  }}
                  {...field}
                />
              )}
            />
          </label>
          <label>
            <div className="ml-2 text-sm">End</div>
            <Controller
              name="end"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <AddressSelect
                  inputProps={{
                    placeholder: "Where are you heading?",
                    disabled: routeLoading,
                  }}
                  {...field}
                />
              )}
            />
          </label>
          <label>
            <div className="ml-2 text-sm">Challenge name</div>
            <input
              className="oc-input"
              placeholder="Challenge name"
              name="name"
              autoComplete="off"
              {...register(`name`, { required: true })}
            />
          </label>
          <input type="hidden" {...register(`route`, { required: true })} />
          <RouteFormError error={routeError} />
          <RouteFormStats loading={routeLoading} routeStats={routeStats} />
          <button
            className="mt-4 p-2 text-center w-full bg-purple-900 font-bold rounded-md disabled:opacity-50 disabled:cursor-not-allowed"
            type="submit"
            disabled={routeError}
          >
            Start Challenge
          </button>
        </div>
      </form>
    )
  );
};

export default RouteForm;
