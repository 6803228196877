import axios from "axios";
import React from "react";
import { useNotificationContext } from "../../context/notification.context";
import { extractMapboxLocationData } from "../../util/Address.util";
import { unknownError } from "../../util/Notification.util";
import Autocomplete from "../shared/autocomplete/Autocomplete";

const AddressSelect = React.forwardRef(({ onChange, ...props }, ref) => {
  const { notify } = useNotificationContext();

  const fetchOptions = async (input) => {
    try {
      const response = await axios.get(
        `${
          process.env.REACT_APP_BACKEND_URL
        }/api/mapbox/geocoding/v5/mapbox.places/${encodeURIComponent(
          input
        )}.json`,
        {
          params: {
            access_token: process.env.REACT_APP_MAPBOX_API_KEY,
          },
        }
      );

      if (response.data.features.length > 0) {
        return response.data.features.map((feature) => ({
          value: {
            coords: feature.center,
            ...extractMapboxLocationData(feature),
          },
          label: feature.place_name,
        }));
      }

      return [];
    } catch (err) {
      console.warn("Could not fetch addresses.", err);
      notify(...unknownError);
      return [];
    }
  };

  return (
    <Autocomplete
      loadOptions={fetchOptions}
      minCharCount={2}
      onChange={onChange}
      ref={ref}
      {...props}
    />
  );
});

export default AddressSelect;
