const { useNavigate, useSearchParams, NavLink } = require("react-router-dom");
const {
  useNotificationContext,
} = require("../../../context/notification.context");
const { useCallback } = require("react");
const { useForm } = require("react-hook-form");
const { default: axiosInstance } = require("../../../util/Axios.util");
const { default: Logo } = require("../../shared/logo/Logo");

const LoginForm = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { notify } = useNotificationContext();
  const { register, handleSubmit } = useForm({
    defaultValues: {
      email: "",
      password: null,
    },
  });

  const submit = useCallback(
    async ({ email, password }) => {
      try {
        const response = await axiosInstance.post(`/auth/login`, {
          email,
          password,
        });
        const token = response.data.token;
        localStorage.setItem("token", token);
        axiosInstance.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${token}`;
        navigate("/");
      } catch (err) {
        console.error(err);
        notify("error", {
          title: "Login failed",
          description:
            err?.response?.data?.error ||
            "An unknown error occurred. Please check the form and try again.",
        });
      }
    },
    [navigate, notify]
  );

  const toRegistration = useCallback(() => {
    searchParams.set("context", "register");
    setSearchParams(searchParams);
  }, [searchParams, setSearchParams]);

  const toForgotPassword = useCallback(() => {
    searchParams.set("context", "forgot-password");
    setSearchParams(searchParams);
  }, [searchParams, setSearchParams]);

  return (
    <form onSubmit={handleSubmit(submit)} className="flex flex-col gap-4">
      <Logo className="w-32 h-32 mx-auto" />
      <label>
        <div className="ml-2 text-sm">E-Mail address</div>
        <input
          className="oc-input"
          name="email"
          type="email"
          {...register(`email`, { required: true })}
        />
      </label>
      <label>
        <div className="ml-2 text-sm">Password</div>
        <input
          className="oc-input"
          name="password"
          autoComplete="off"
          type="password"
          {...register(`password`, { required: true })}
        />
      </label>
      <button className="bg-lime-300 hover:bg-lime-400 text-black p-2 rounded-md font-bold">
        Sign in
      </button>
      <button
        type="button"
        className="block p-2 w-full text-center"
        onClick={toRegistration}
      >
        Register
      </button>
      <hr />
      <button
        type="button"
        className="block p-2 w-full text-center"
        onClick={toForgotPassword}
      >
        Forgot your password?
      </button>
    </form>
  );
};

export default LoginForm;
