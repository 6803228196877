import moment from "moment";
import { useContext } from "react";
import UserContext from "../../../context/user.context";

const ProfileContents = ({ className }) => {
  const { user } = useContext(UserContext);

  return (
    user && (
      <div className={`bg-neutral-950 py-8 px-4 md:p-12 ${className}`}>
        <h2 className="text-left md:text-left text-white mb-0 text-xl md:text-4xl font-medium">
          {user.displayName}
        </h2>
        <span className="text-sm text-neutral-400">
          Joined {moment(user.createdAt).format("DD.MM.YYYY")}
        </span>
      </div>
    )
  );
};

export default ProfileContents;
