const TermsOfService = () => {
  return (
    <div className="oc-container">
      <div className="oc-content prose prose-sm sm:prose lg:prose-lg xl:prose-2xl mx-auto">
        <h2 className="text-2xl font-bold">Terms of Service</h2>
        <p className="text-md mb-2">
          These terms and conditions ("Terms") govern the use of PixelPace
          ("Application") provided by Benjamin Adam ("Provider"). Please read
          these Terms carefully. By using the Application, you agree to these
          Terms. If you don't agree to these Terms, do not use the Application.
        </p>

        <h3 className="text-xl font-bold mt-4">Use of the Application</h3>
        <p className="text-md mb-2">
          You may use the Application for personal, non-commercial purposes, and
          subject to these Terms, you are granted a limited, personal,
          non-transferable, and non-exclusive license to use the Application.
        </p>

        <h3 className="text-xl font-bold mt-4">Content</h3>
        <p className="text-md mb-2">
          All software, data, text, images, sounds, videos, and other content
          made available through the Application ("Content") are owned by the
          Provider or its licensors. You agree to not use or disclose the
          Content except as expressly permitted by these Terms.
        </p>

        <h3 className="text-xl font-bold mt-4">Privacy</h3>
        <p className="text-md mb-2">
          Your use of the Application is subject to the Provider's Privacy
          Statement, which is incorporated into these Terms by this reference.
        </p>

        <h3 className="text-xl font-bold mt-4">Prohibited Use</h3>
        <p className="text-md mb-2">
          You agree to use the Application only for its intended purpose. You
          must use the Application in compliance with all privacy, data
          protection, intellectual property, and other applicable laws. The
          following uses of the Application are prohibited: (i) any use that
          interferes or disrupts the Application or the servers or networks
          connected to the Application; (ii) unauthorized access to or use of
          data, systems, or networks, including any attempt to probe, scan, or
          test the vulnerability of the Application or to breach security or
          authentication measures; and (iii) use of the Application to send
          unsolicited email, including without limitation promotions or
          advertisements for products or services.
        </p>

        <h3 className="text-xl font-bold mt-4">
          Modifications to the Application
        </h3>
        <p className="text-md mb-2">
          The Provider reserves the right to modify or discontinue, temporarily
          or permanently, the Application or any features or portions thereof
          without prior notice.
        </p>

        <h3 className="text-xl font-bold mt-4">Termination</h3>
        <p className="text-md mb-2">
          The Provider may terminate your access to and use of the Application
          at our sole discretion, at any time, and without notice to you.
        </p>

        <h3 className="text-xl font-bold mt-4">Contact</h3>
        <p className="text-md mb-2">
          If you have any questions about these Terms, please contact the
          Provider at m.benjamin.adam@gmail.com.
        </p>
      </div>
    </div>
  );
};

export default TermsOfService;
