import moment from "moment";
import DashboardTile from "../../dashboard-tile/DashboardTile";

const Today = ({ className }) => {
  return (
    <DashboardTile title=" " className={`flex-[1] hidden md:flex ${className}`}>
      <div className="mt-4 ml-6">
        <h1 className="text-6xl">{moment().format("dddd")}</h1>
        <div className="text-md text-neutral-400">
          {moment().format("DD.MM.YYYY")}
        </div>
      </div>
    </DashboardTile>
  );
};

export default Today;
