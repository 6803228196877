import * as turf from "@turf/turf";
import { useMemo } from "react";

function changeCoordFormat([a, b]) {
  return [b, a];
}

function changeCoordsFormat(coords) {
  return [...coords].map(changeCoordFormat);
}

export function useRouteCoverage(route, progress) {
  return useMemo(() => {
    if (!route || isNaN(progress)) {
      return {
        route: null,
        splitPoint: null,
        coveredRoute: null,
        remainingRoute: null,
      };
    }

    const geojsonRoute = changeCoordsFormat(route);

    const line = turf.lineString(geojsonRoute);

    // Find the point N meters along the line
    const splitPoint = turf.along(line, progress / 1000, {
      units: "kilometers",
    });

    // Split the line at the split point
    const firstHalf = turf.lineSlice(
      turf.point(geojsonRoute[0]),
      splitPoint,
      line
    );
    const secondHalf = turf.lineSlice(
      splitPoint,
      turf.point(geojsonRoute[geojsonRoute.length - 1]),
      line
    );

    // Convert the split lines back into coordinate arrays and return them
    return {
      route,
      splitPoint: changeCoordFormat(turf.getCoords(splitPoint)),
      coveredRoute: changeCoordsFormat(turf.getCoords(firstHalf)),
      remainingRoute: changeCoordsFormat(turf.getCoords(secondHalf)),
    };
  }, [progress, route]);
}
